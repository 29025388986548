import { useNavigate } from "react-router-dom";
import { routes } from "../router/routes";

const useRedirectToHome = () => {
    const navigate = useNavigate();
    return () => {
        navigate(routes.homeFull);
    };
};

export default useRedirectToHome;
