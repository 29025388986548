import classNames from 'classnames'
import React from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil';
import useScreenSize from '../hooks/useScreenSize';
import { showSidebarSelector } from '../recoil/atoms/themeAtoms';

import LeftSidebar from "../components/LeftSidebar";
import Header from "../components/Header";

const Dashboard = () => {
    const navigate = useNavigate();

    const screenSize = useScreenSize();

    const showSidebar = useRecoilValue(showSidebarSelector);

    return (
        <div
            className={classNames("page-wrapper", { "show-sidebar": showSidebar })}
            id="main-wrapper"
            data-layout="vertical"
            data-navbarbg="skin6"
            data-sidebartype={screenSize.width > 1200 ? "full" : "mini-sidebar"}
            data-sidebar-position="fixed"
            data-header-position="fixed"
        >
            <LeftSidebar />
            <div className="body-wrapper">
                <Header />
                <div className="container-fluid">
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default Dashboard