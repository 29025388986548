import React from 'react'
import classNames from 'classnames';

function Paginator({ page, setPage, totalPage }) {
    return (
        <nav>
            <ul className="pagination justify-content-center">

                <li className={classNames("page-item", { disabled: page == 1 || totalPage <= 1 })}>
                    <button className="page-link" disabled={page == 1 || totalPage <= 1} onClick={() => setPage(page - 1)} aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                    </button>
                </li>

                {totalPage > 5 && (
                    <>
                        {[...Array(2)].map((val, key) => (
                            <li className={classNames("page-item", { active: key + 1 == page })} key={key}
                                onClick={() => setPage(key + 1)}>
                                <button className="page-link" >{key + 1}</button>
                            </li>
                        ))}
                        {(page > 2 && page < totalPage - 1) && (
                            <>
                                {page > 3 && (
                                    <>
                                        <li className="page-item">
                                            <button className="page-link" disabled >...</button>
                                        </li>
                                        <li className="page-item"
                                            onClick={() => setPage(page - 1)}>
                                            <button className="page-link" >{page - 1}</button>
                                        </li>
                                    </>
                                )}
                                <li className="page-item active"
                                    onClick={() => setPage(page)}>
                                    <button className="page-link" >{page}</button>
                                </li>
                                {page < totalPage - 2 && (
                                    <>
                                        <li className="page-item"
                                            onClick={() => setPage(page + 1)}>
                                            <button className="page-link" >{page + 1}</button>
                                        </li>
                                        <li className="page-item">
                                            <button className="page-link" disabled >...</button>
                                        </li>
                                    </>
                                )}
                            </>
                        ) || (<li className="page-item">
                            <button className="page-link" disabled >...</button>
                        </li>)}

                        {[...Array(2)].map((val, key) => (
                            <li className={classNames("page-item", { active: totalPage - (1 - key) == page })} key={key}
                                onClick={() => setPage(totalPage - (1 - key))}>
                                <button className="page-link" >{totalPage - (1 - key)}</button>
                            </li>
                        ))}
                    </>
                ) || [...Array(totalPage)].map((val, key) => (
                    <li className={classNames("page-item", { active: key + 1 == page })} key={key}
                        onClick={() => setPage(key + 1)}>
                        <button className="page-link" >{key + 1}</button>
                    </li>
                ))}


                <li className={classNames("page-item", { disabled: page == totalPage || totalPage <= 1 })}>
                    <button className="page-link" disabled={page == totalPage || totalPage <= 1} onClick={() => setPage(page + 1)} aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                    </button>
                </li>
            </ul>
        </nav >
    )
}

export default Paginator