import React from 'react'
import moment from "moment-timezone"

import UserPermissions from './UserPermissions';
import { Can } from '../../helpers/Can';

function UserRecord({ user, getUsers, handleShowShareMerchant }) {
    const tz = moment.tz.guess();

    const convertToTimezone = (dateTime) => {
        return moment(dateTime).tz(tz).format("DD/MM/YYYY h:mm:ss A");
    }

    return (
        <tr>
            <td className="border-bottom-0">
                <div className='flex'>
                    <p className="mb-0"><small className='fw-semibold'>Name:</small> {user.name}</p>
                    <p className="mb-0 mt-1"><small className='fw-semibold'>Role:</small>  {user.role}</p>
                    <p className="mb-0 mt-1"><small className='fw-semibold'>Owner:</small>  {user?.owner?.name}</p>
                </div>
            </td>
            <Can I="read" a="Permission">
                <td className="border-bottom-0">
                    {user?.permission && (
                        <UserPermissions perms={user?.permission} user={user._id} role={user.role} getUsers={getUsers} />
                    )}
                </td>
            </Can>
            <td className="border-bottom-0">
                <div className="d-flex align-items-center gap-2 shared-merchants-col">
                    {user.sharedMerchants && !!user.sharedMerchants.length && (
                        user.sharedMerchants.map((merchant, key) => (
                            <button className="d-flex align-items-center btn btn-sm btn-outline-secondary rounded-3 fw-semibold" key={key}>
                                <span>{merchant.name}</span>
                            </button>
                        ))
                    )}
                    <button onClick={() => handleShowShareMerchant(user._id, user.sharedMerchants)}
                        className='btn btn-sm btn-outline-primary rounded-3 fw-semibold'>
                        <i className="ti ti-share me-1"></i>
                        <span>Share More</span>
                    </button>
                </div>
            </td>
            <td className="border-bottom-0">
                <p className="mb-0 fw-normal">{convertToTimezone(user.createdAt)}</p>
            </td>
        </tr>
    )
}

export default UserRecord