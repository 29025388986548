import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import toast from 'react-hot-toast';
import * as Yup from "yup";
import useCustomValidation from '../../../hooks/useCustomValidation';
import useLogout from '../../../hooks/useLogout';
import ValidationError from '../../ValidationError';

function UpdateNEFTModal({ updateData, show, handleClose }) {
    const logoutUser = useLogout();

    const [isLoading, setIsLoading] = useState(false);
    const [neftActiveStatus, setNEFTActiveStatus] = useState(null);

    const schema = {
        neft: Yup.string().required("NEFT is required"),
        merchant: Yup.string().required("Merchant is required"),
        acNumber: Yup.string().required("Account Number is required").trim(),
        acHolderName: Yup.string().required("Account Holder Name is required").trim(),
        ifscCode: Yup.string().required("IFSC Code is required").trim(),
        bankName: Yup.string().required("Bank Name is required").trim(),
        enabled: Yup.bool().required("Active is required"),
    };
    const initial = {
        neft: null,
        merchant: null,
        acNumber: null,
        acHolderName: null,
        ifscCode: null,
        bankName: null,
        enabled: null,
    };
    const { register, errors, handleSubmit, reset, getValues, setValue } =
        useCustomValidation({ schema, initial });

    useEffect(() => {
        setValue("neft", updateData.neft)
        setValue("merchant", updateData.merchant)
        setValue("acNumber", updateData.acNumber)
        setValue("acHolderName", updateData.acHolderName)
        setValue("ifscCode", updateData.ifscCode)
        setValue("bankName", updateData.bankName)
        setValue("enabled", updateData.enabled)
        setNEFTActiveStatus(updateData.enabled)

        return () => { }
    }, [updateData])

    const toggleActive = () => {
        const status = getValues("enabled")
        setValue("enabled", !status)
        setNEFTActiveStatus(!status)
    }

    const updateUpiSubmit = async (data) => {
        setIsLoading(true)
        await axios
            .put(`${process.env.REACT_APP_API_URL}/merchant/neft/update`,
                { ...data, merchant: updateData.merchant })
            .then((response) => {
                const { data } = response;
                if (response.status == 200) {
                    toast.success(data.msg);
                    reset()
                    handleClose("success")
                } else {
                    toast.error(data.msg);
                }
            })
            .catch((error) => {
                const { response } = error
                if (response) {
                    const { data } = response
                    if (response?.status == 401) {
                        toast.error("Please Login!");
                        logoutUser();
                    } else if (response?.status == 400) {
                        toast.error(data.msg);
                    } else {
                        toast.error(data.msg || "Request failed!");
                    }
                }
            })
            .finally((e) => {
                setIsLoading(false)
            });
    }

    const handleOnHide = () => {
        reset()
        handleClose("manual")
    }

    return (
        neftActiveStatus != null && (
            <Modal show={show} onHide={handleOnHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Update NEFT</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit(updateUpiSubmit)}>
                        <input type="text" {...register("neft")} hidden />
                        <input type="text" {...register("merchant")} hidden />

                        <div className="mb-3">
                            <label htmlFor='u-neft-acNumber' className="form-label">Account Number</label>
                            <input type="text" {...register("acNumber")} className="form-control"
                                id="u-neft-acNumber" />
                            <ValidationError name="acNumber" errors={errors} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor='u-neft-acHolderName' className="form-label">Account Holder Name</label>
                            <input {...register("acHolderName")} type="text" className="form-control"
                                id="u-neft-acHolderName" />
                            <ValidationError name="acHolderName" errors={errors} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor='u-neft-ifscCode' className="form-label">IFSC Code</label>
                            <input {...register("ifscCode")} type="text" className="form-control"
                                id="u-neft-ifscCode" />
                            <ValidationError name="ifscCode" errors={errors} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor='u-neft-bankName' className="form-label">Bank Name</label>
                            <input {...register("bankName")} type="text" className="form-control"
                                id="u-neft-bankName" />
                            <ValidationError name="bankName" errors={errors} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor='u-neft-enabled' className="form-label">Active</label>
                            <div className="form-check form-switch">
                                <input {...register("enabled")} value={neftActiveStatus} onChange={toggleActive} className="form-check-input" type="checkbox"
                                    id="u-neft-enabled" checked={neftActiveStatus} />
                                <label className="form-check-label" htmlFor="u-neft-enabled">NEFT is {neftActiveStatus ? "Active" : "Inactive"}</label>
                            </div>
                            <ValidationError name="enabled" errors={errors} />
                        </div>

                        <button type="submit" className="btn btn-primary"
                            disabled={isLoading}>
                            {isLoading && (
                                <div className="spinner-border"></div>
                            )} Update
                        </button>
                    </form>
                </Modal.Body>
            </Modal>
        )
    )
}

export default UpdateNEFTModal