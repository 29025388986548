import { Link } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { sidebarAtom } from "../recoil/atoms/themeAtoms";
import { routes } from "../router/routes";
import { Can } from "../helpers/Can";

export default function LeftSidebar() {
  const [showSidebar, setShowSidebar] = useRecoilState(sidebarAtom);

  return (
    <aside className="left-sidebar">
      <div>
        <div className="brand-logo d-flex align-items-center justify-content-between">
          <Link
            to={routes.homeFull}
            onClick={() => setShowSidebar(false)}
            className="text-nowrap logo-img"
          >
            <h3>Payment Panel</h3>
          </Link>
          <div
            onClick={() => setShowSidebar(false)}
            className="close-btn d-xl-none d-block sidebartoggler cursor-pointer"
            id="sidebarCollapse"
          >
            <i className="ti ti-x fs-8"></i>
          </div>
        </div>

        <nav className="sidebar-nav scroll-sidebar">
          <ul id="sidebarnav">
            {/* <li className="nav-small-cap">
              <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
              <span className="hide-menu">Payment Panel</span>
            </li> */}

            <Can I="read" a="Merchant">
              <li className="sidebar-item">
                <Link
                  to={routes.merchantsFull}
                  onClick={() => setShowSidebar(false)}
                  className="sidebar-link"
                  aria-expanded="false"
                >
                  <span>
                    <i className="ti ti-building-bank"></i>
                  </span>
                  <span className="hide-menu">Merchants</span>
                </Link>
              </li>
            </Can>

            <Can I="read" a="Order">
              <li className="sidebar-item">
                <Link
                  to={routes.ordersFull}
                  onClick={() => setShowSidebar(false)}
                  className="sidebar-link"
                  aria-expanded="false"
                >
                  <span>
                    <i className="ti ti-credit-card"></i>
                  </span>
                  <span className="hide-menu">Orders</span>
                </Link>
              </li>
            </Can>

            <Can I="read" a="Statement">
              <li className="sidebar-item">
                <Link
                  to={routes.statementsFull}
                  onClick={() => setShowSidebar(false)}
                  className="sidebar-link"
                  aria-expanded="false"
                >
                  <span>
                    <i className="ti ti-file-invoice"></i>
                  </span>
                  <span className="hide-menu">Statements</span>
                </Link>
              </li>
            </Can>

            <Can I="read" a="User">
              <li className="sidebar-item">
                <Link
                  to={routes.usersFull}
                  onClick={() => setShowSidebar(false)}
                  className="sidebar-link"
                  aria-expanded="false"
                >
                  <span>
                    <i className="ti ti-users"></i>
                  </span>
                  <span className="hide-menu">Users</span>
                </Link>
              </li>
            </Can>

          </ul>
        </nav>

      </div>
    </aside>
  );
}
