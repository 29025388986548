import axios from 'axios'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { useAbility } from '@casl/react';

import Paginator from '../components/pagination/Paginator';
import PerPage from '../components/pagination/PerPage';
import StatementRecord from '../components/statements/StatementRecord';
import UploadStatementModal from '../components/statements/UploadStatementModal';
import usePagination from '../hooks/usePagination';
import SucceedOrdersModal from '../components/statements/SucceedOrdersModal';
import { AbilityContext, Can } from '../helpers/Can';
import useRedirectToHome from '../hooks/useRedirectToHome';
import useLogout from '../hooks/useLogout';

const Statement = () => {
  const logoutUser = useLogout();
  const redirectToHome = useRedirectToHome();
  const ability = useAbility(AbilityContext);

  useEffect(() => {
    // console.log(ability, ability.cannot('read', 'Statement'))
    if (ability && ability.cannot('read', 'Statement')) {
      redirectToHome()
    }
    return () => { }
  }, [ability])

  const { limit, setLimit, perPageArr, page, setPage, totalPage, setTotalPage, totalRecords, setTotalRecords } = usePagination({ limit: 10 })

  const [statements, setStatements] = useState(null)
  const [showUploadStatement, setShowUploadStatement] = useState(false)
  const [showSuccedOrders, setShowSuccedOrders] = useState(false)
  const [succedOrdersData, setSuccedOrdersData] = useState(null)

  const [merch, setMerch] = useState(null)
  const [selectedMerch, setSelectedMerch] = useState(null)

  useEffect(() => {
    const getMerchants = async () => {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/merchant/dropdown`)
        .then((response) => {
          if (response.status == 200) {
            const { data } = response;
            setMerch(data.merchants)
            const defaultM = _.find(data.merchants, (o) => o.default == true)
            // console.log(defaultM)
            if (defaultM) {
              setSelectedMerch(defaultM._id)
            }

          } else {
            setMerch(null)
          }
        })
        .catch((error) => {
          const { response } = error
          if (response) {
            if (response?.status == 401) {
              toast.error("Please Login!");
              logoutUser();
            }
          }
          setMerch(null)
        })
    }

    getMerchants()
    return () => { }
  }, [])


  const getStatements = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/bankstatement/all?page=${page}&limit=${limit}`)
      .then((response) => {
        if (response.status == 200) {
          const { data } = response;
          setStatements(data.bankStatements)
          setTotalRecords(data.pagination.totalRecords)
          setTotalPage(data.pagination.totalPage)
          setPage(data.pagination.currentPage)
        } else {
          setStatements(null)
          setTotalRecords(0)
          setTotalPage(0)
          setPage(1)
        }
      })
      .catch((error) => {
        setStatements(null)
        setTotalRecords(0)
        setTotalPage(0)
        setPage(1)
      })
  }

  useEffect(() => {

    getStatements()

    return () => { }
  }, [page, limit])

  // UPLOAD STATEMENT START
  const handleUploadStatementShow = () => {
    setShowUploadStatement(true)
  }
  const handleUploadStatementClose = () => {
    setShowUploadStatement(false)
  }
  const UploadStatementClose = (type) => {
    handleUploadStatementClose()
    if (type == "success") {
      getStatements()
    }
  }
  // UPLOAD STATEMENT END

  // SUCCED ORDERS START
  const handleSuccedOrdersShow = (orders) => {
    if (orders?.length) {
      setSuccedOrdersData(orders)
      setShowSuccedOrders(true)
    } else {
      toast.error("There are no Succeed Orders")
    }
  }
  const handleSuccedOrdersClose = () => {
    setShowSuccedOrders(false)
    setSuccedOrdersData(null)
  }
  // SUCCED ORDERS END

  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title fw-semibold mb-4">Upload Statement
          <span className='ms-2 pointer' onClick={getStatements}>
            <i className="ti ti-reload"></i>
          </span>
        </h5>


        <div>
          <Can I="upload" a="Statement">
            <button className="btn btn-primary rounded-3 fw-semibold" onClick={handleUploadStatementShow}>Upload New Statement</button>
            {showUploadStatement && (
              <UploadStatementModal show={showUploadStatement} merch={merch} selectedMerch={selectedMerch} handleClose={UploadStatementClose} />
            )}
          </Can>

          <SucceedOrdersModal show={showSuccedOrders} succedOrdersData={succedOrdersData} handleClose={handleSuccedOrdersClose} />

          <div className="row mt-1">
            <div className="col">
              <PerPage limit={limit} setLimit={setLimit} perPageArr={perPageArr} totalRecords={totalRecords} />
            </div>
          </div>

          {statements && !!statements.length && (
            <>
              <div className="table-responsive w-100">
                <table className="table text-nowrap mb-0 align-middle">
                  <thead className="text-dark fs-4">
                    <tr>
                      <th className="border-bottom-0">
                        <h6 className="fw-semibold mb-0">Merchant Name</h6>
                      </th>
                      <th className="border-bottom-0">
                        <h6 className="fw-semibold mb-0">Statement Type</h6>
                      </th>
                      <th className="border-bottom-0">
                        <h6 className="fw-semibold mb-0">Statement File</h6>
                      </th>
                      <th className="border-bottom-0">
                        <h6 className="fw-semibold mb-0">Succeed Orders</h6>
                      </th>
                      <th className="border-bottom-0">
                        <h6 className="fw-semibold mb-0">Uploaded By</h6>
                      </th>
                      <th className="border-bottom-0">
                        <h6 className="fw-semibold mb-0">Uploaded At</h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {statements.map((statement, key) => (
                      <StatementRecord showSuccedOrders={handleSuccedOrdersShow} statement={statement} key={key} />
                    ))}
                  </tbody>
                </table>
              </div>
              <Paginator page={page} setPage={setPage} totalPage={totalPage} />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Statement