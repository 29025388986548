import axios from 'axios';
import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import toast from 'react-hot-toast';
import * as Yup from "yup";
import useCustomValidation from '../../hooks/useCustomValidation';
import useLogout from '../../hooks/useLogout';
import ValidationError from '../ValidationError';

function ApproveOrderModal({ show, handleClose, amount, transId }) {
    const logoutUser = useLogout();

    const [isLoading, setIsLoading] = useState(false);

    const schema = {
        upiRefId: Yup
            .string()
            .matches(/^[0-9]+$/, "UTR Number must be only digits")
            .length(12, "UTR Number must be exactly 12 characters")
            .required("UTR number is required")
            .trim(),
        // .string()
        // .min(8, 'UTR Number must be at least 8 characters')
        // .max(16, 'UTR Number must be at most 16 characters')
        // .matches(/^[^.]*$/, {
        //     message: "UTR Number does not cointain period"
        // })
        // .matches(/^[^!@#$%^&*+=<>:;|~]*$/, {
        //     message: 'UTR Number does not cointain symbols'
        // })
        // .matches(/^[a-zA-Z0-9]+$/, "UTR Number must be only digits")
        // .required("UTR number is required")
        // .trim(),
        transId: Yup.string()
            .required("Transaction Id is required"),
        amount: Yup.string()
            .required("Amount is required"),
    };
    const initial = {
        upiRefId: null,
        amount: amount,
        transId: transId,
    };
    const { register, errors, handleSubmit, reset, setValue } =
        useCustomValidation({ schema, initial });

    const approveOrder = async (data) => {
        const params = {
            transId,
            amount,
            upiRefId: data.upiRefId
        }

        setIsLoading(true)
        await axios
            .post(`${process.env.REACT_APP_API_URL}/order/approve`, params)
            .then((response) => {
                const { data } = response;
                if (response.status == 200) {
                    toast.success(data.msg);
                    reset()
                    handleClose("success")
                } else {
                    toast.error(data.msg);
                }
            })
            .catch((error) => {
                const { response } = error
                if (response) {
                    const { data } = response
                    if (response?.status == 401) {
                        toast.error("Please Login!");
                        logoutUser();
                    } else if (response?.status == 400) {
                        toast.error(data.msg);
                    } else {
                        toast.error(data.msg || "Request failed!");
                    }
                }
            })
            .finally((e) => {
                setIsLoading(false)
            });

    }

    return (
        <Modal show={show} onHide={() => handleClose("manual")}>
            <Modal.Header closeButton>
                <Modal.Title>Approve Order</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(approveOrder)}>
                    <div className="mb-3">
                        <label className="form-label">Transaction Id</label>
                        <input {...register("transId")} type="text" hidden className="form-control" />
                        <p>{transId}</p>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Amount</label>
                        <input {...register("transId")} type="text" hidden className="form-control" />
                        <p>{amount}</p>
                    </div>
                    <div className="mb-3">
                        <label htmlFor='approve-utr-number' className="form-label">UTR Number</label>
                        <input type="text" {...register("upiRefId")} className="form-control"
                            id="approve-utr-number" />
                        <ValidationError name="upiRefId" errors={errors} />
                    </div>

                    <button type="submit" className="btn btn-primary"
                        disabled={isLoading}>
                        {isLoading && (
                            <div className="spinner-border"></div>
                        )} Approve
                    </button>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default ApproveOrderModal