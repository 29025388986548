import { createBrowserRouter } from "react-router-dom";
import Login from "../pages/Login";
import Dashboard from "../layouts/Dashboard.jsx";
import Home from "../pages/Home";
import ProtectedRoute from "./ProtectedRoute";
import Statements from "../pages/Statements";
import Orders from "../pages/Orders";
import Merchants from "../pages/Merchants";
import Payment from "../pages/Payment";
import Error404 from "../pages/404.jsx";
import Users from "../pages/Users.jsx";
// import Test from "../pages/Test";

export const routes = {
    // test: "test",
    // testFull: "/test",
    payment: "payment",
    paymentFull: "/payment",
    login: "",

    dashboard: "dashboard",

    home: "home",
    homeFull: "/dashboard/home",
    orders: "orders",
    ordersFull: "/dashboard/orders",
    merchants: "merchants",
    merchantsFull: "/dashboard/merchants",
    statements: "statements",
    statementsFull: "/dashboard/statements",
    users: "users",
    usersFull: "/dashboard/users",

};

export const router = createBrowserRouter([
    // {
    //     path: routes.test,
    //     element: <Test />,
    // },
    {
        path: routes.payment,
        element: <Payment />,
    },
    {
        path: routes.login,
        element: <Login />,
    },
    {
        path: "/",
        element: <ProtectedRoute />,
        children: [
            {
                path: routes.dashboard,
                element: <Dashboard />,
                children: [
                    {
                        path: routes.home,
                        element: <Home />,
                    },
                    {
                        path: routes.merchants,
                        element: <Merchants />,
                    },
                    {
                        path: routes.orders,
                        element: <Orders />,
                    },
                    {
                        path: routes.statements,
                        element: <Statements />,
                    },
                    {
                        path: routes.users,
                        element: <Users />,
                    },
                    {
                        path: "*",
                        element: <Error404 />
                    }
                ],
            },
        ],
    },
]);
