const { createMongoAbility } = require("@casl/ability");

let ANONYMOUS_ABILITY

export const defineAbility = (rules) => {
    if (rules) {
        return new createMongoAbility(rules)
    }

    ANONYMOUS_ABILITY = ANONYMOUS_ABILITY || new createMongoAbility();
    return ANONYMOUS_ABILITY;
}
