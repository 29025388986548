import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import toast from 'react-hot-toast';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import useLogout from '../../hooks/useLogout';

function ShareMerchantModal({ show, shareMerchantData, handleClose, merchants }) {
    const logoutUser = useLogout();

    const animatedComponents = makeAnimated();

    const [isLoading, setIsLoading] = useState(false);
    const [selectedMerchants, setSelectedMerchants] = useState()

    useEffect(() => {
        setSelectedMerchants(shareMerchantData.sharedMerchant)

        return () => { }
    }, [shareMerchantData])


    const shareMerchantSubmit = async (e) => {
        e.preventDefault()
        // console.log(selectedMerchants, shareMerchantData._id)
        const selectedMerchantsArr = selectedMerchants.map((selected) => { return selected.value })
        const data = { user: shareMerchantData._id, shared: selectedMerchantsArr }
        setIsLoading(true)
        await axios
            .put(`${process.env.REACT_APP_API_URL}/user/update_shared_merchant`, data)
            .then((response) => {
                const { data } = response;
                if (response.status == 200) {
                    toast.success(data.msg);
                    handleClose("success")
                } else {
                    toast.error(data.msg);
                }
            })
            .catch((error) => {
                const { response } = error
                if (response) {
                    const { data } = response
                    if (response?.status == 401) {
                        toast.error("Please Login!");
                        logoutUser();
                    } else if (response?.status == 400) {
                        toast.error(data.msg);
                    } else {
                        toast.error(data.msg || "Request failed!");
                    }
                }
            })
            .finally((e) => {
                setIsLoading(false)
            });

    }

    const handleMerchantChange = (selectedOption) => {
        setSelectedMerchants(selectedOption)
    }

    const onHideHandle = () => {
        setSelectedMerchants(null)
        handleClose("manual")
    }

    return (
        <Modal show={show} onHide={onHideHandle} >
            <Modal.Header closeButton>
                <Modal.Title>Shared Merchants</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={shareMerchantSubmit} autoComplete="off">
                    <Select
                        className='mb-3'
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        value={selectedMerchants}
                        onChange={handleMerchantChange}
                        options={merchants}
                        isMulti
                    />

                    <button type="submit" className="btn btn-primary"
                        disabled={isLoading}>
                        {isLoading && (
                            <div className="spinner-border"></div>
                        )} Update
                    </button>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default ShareMerchantModal