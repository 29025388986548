import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import toast from 'react-hot-toast';
import { useRecoilValue } from 'recoil';
import * as Yup from "yup";
import useLogout from '../../hooks/useLogout';
import { authTokenSelector } from '../../recoil/atoms/userAtom';
import useCustomValidation from '../../hooks/useCustomValidation';
import ValidationError from '../ValidationError';
import { jwtDecode } from 'jwt-decode';

function UpdateMerchantModal({ updateData, show, handleClose }) {

    const token = useRecoilValue(authTokenSelector);
    const decodedUser = jwtDecode(token);
    const logoutUser = useLogout();

    const [isLoading, setIsLoading] = useState(false);
    const [userList, setUserList] = useState(null);
    const [defaultMerchant, setDefaultMerchant] = useState(null);

    useEffect(() => {
        const getUserList = async () => {
            await axios
                .get(`${process.env.REACT_APP_API_URL}/merchant/user_list`)
                .then((response) => {
                    if (response.status == 200) {
                        const { data } = response;
                        setUserList(data.users)
                    } else {
                        setUserList(null)
                    }
                })
                .catch((error) => {
                    const { response } = error
                    if (response) {
                        const { data } = response
                        if (response?.status == 401) {
                            toast.error("Please Login!");
                            logoutUser();
                        } else if (response?.status == 400) {
                            setUserList(null)
                            toast.error(data.msg);
                        } else {
                            setUserList(null)
                            toast.error(data.msg || "Request failed!");
                        }
                    }
                })
                .finally((e) => {

                });
        }

        if (decodedUser && (decodedUser?.role == 'su' || decodedUser?.role == 'admin')) {
            getUserList()
        }
        return () => {

        }
    }, [])

    const schema = {
        id: Yup.string().required("Merchant Id is required"),
        name: Yup.string().required("Merchant Name is required").trim(),
        min: Yup.number().required("Min. Amount is required"),
        max: Yup.number().required("Max. Amount is required").moreThan(Yup.ref("min")),
        callBackUrl: Yup.string().url().required(),
        default: Yup.bool().required(),
        owner: Yup.string().nullable(),
    };
    const initial = {
        id: null,
        name: null,
        min: null,
        max: null,
        callBackUrl: null,
        default: null,
        owner: null
    };
    const { register, errors, handleSubmit, reset, getValues, setValue } =
        useCustomValidation({ schema, initial });

    useEffect(() => {
        setValue("id", updateData.merchant)
        setValue("name", updateData.name)
        setValue("min", updateData.min)
        setValue("max", updateData.max)
        setValue("callBackUrl", updateData.callBackUrl)
        setValue("default", updateData.default)
        setValue("owner", updateData.owner)
        setDefaultMerchant(updateData.default)

        return () => { }
    }, [updateData])

    const toggleActive = () => {
        const status = getValues("default")
        setValue("default", !status)
        setDefaultMerchant(!status)
    }

    const updateMerchantSubmit = async (data) => {
        // console.log(data)
        setIsLoading(true)
        await axios
            .put(`${process.env.REACT_APP_API_URL}/merchant/update`, data)
            .then((response) => {
                const { data } = response;
                if (response.status == 200) {
                    toast.success(data.msg);
                    reset()
                    handleClose("success")
                } else {
                    toast.error(data.msg);
                }
            })
            .catch((error) => {
                const { response } = error
                if (response) {
                    const { data } = response
                    if (response?.status == 401) {
                        toast.error("Please Login!");
                        logoutUser();
                    } else if (response?.status == 400) {
                        toast.error(data.msg);
                    } else {
                        toast.error(data.msg || "Request failed!");
                    }
                }
            })
            .finally((e) => {
                setIsLoading(false)
            });
    }

    const handleOnHide = () => {
        reset()
        handleClose("manual")
    }

    return (
        defaultMerchant != null && (
            <Modal show={show} onHide={handleOnHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Merchant</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit(updateMerchantSubmit)}>
                        <input type="text" {...register("id")} hidden />

                        <div className="mb-3">
                            <label htmlFor='u-merchant-name' className="form-label">Merchant Name</label>
                            <input type="text" {...register("name")} className="form-control"
                                id="u-merchant-name" />
                            <ValidationError name="name" errors={errors} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor='u-merchant-min' className="form-label">Min. Amount</label>
                            <input type="number" {...register("min")} className="form-control"
                                id="u-merchant-min" />
                            <ValidationError name="min" errors={errors} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor='u-merchant-max' className="form-label">Max. Amount</label>
                            <input type="number" {...register("max")} className="form-control"
                                id="u-merchant-max" />
                            <ValidationError name="max" errors={errors} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor='u-merchant-callback' className="form-label">Callback Url</label>
                            <input type="string" {...register("callBackUrl")} className="form-control"
                                id="u-merchant-callback" />
                            <ValidationError name="callBackUrl" errors={errors} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor='u-merchant-default' className="form-label">Default</label>
                            <div className="form-check form-switch">
                                <input {...register("default")} value={defaultMerchant} onChange={toggleActive} className="form-check-input" type="checkbox"
                                    id="u-merchant-default" checked={defaultMerchant} />
                                <label className="form-check-label" htmlFor="u-merchant-default">Merchant is {defaultMerchant ? "" : "not"} set as default</label>
                            </div>
                            <ValidationError name="default" errors={errors} />
                        </div>

                        {decodedUser && (decodedUser?.role == 'su' || decodedUser?.role == 'admin') && (
                            <div className="mb-3">
                                <label htmlFor='u-merchant-owner' className="form-label">Owner <small>(optional)</small></label>
                                <select id="u-merchant-owner" className="form-select" {...register("owner")}>
                                    <option value="" disabled>
                                        Select Owner
                                    </option>
                                    {userList && !!userList.length && (
                                        userList.map((user, key) => (
                                            <option key={key} value={user._id}>{user.name}</option>
                                        ))
                                    )}
                                </select>
                                <ValidationError name="owner" errors={errors} />
                            </div>
                        )}

                        <button type="submit" className="btn btn-primary"
                            disabled={isLoading}>
                            {isLoading && (
                                <div className="spinner-border"></div>
                            )} Update
                        </button>
                    </form>
                </Modal.Body>
            </Modal>
        )
    )
}

export default UpdateMerchantModal