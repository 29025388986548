import { ErrorMessage } from "@hookform/error-message/dist";
import React from "react";

export default function ValidationError({ name, errors }) {
  return (
    <ErrorMessage
      errors={errors}
      name={name}
      render={({ message }) => {
        return <div className="form-text text-danger">{message}</div>;
      }}
    />
  );
}
