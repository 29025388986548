import React from 'react'
import Modal from 'react-bootstrap/Modal';

function SucceedOrdersModal({ show, handleClose, succedOrdersData }) {
    return (
        <Modal show={show} onHide={() => handleClose("manual")} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>Succeed Orders</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ol className="list-group list-group-numbered">
                    {succedOrdersData && succedOrdersData.length && (
                        succedOrdersData.map((data, key) => (
                            <li key={key} className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div><small className='fw-bold'>Usename:</small> {data.uname}</div>
                                    <span>
                                        <small className='fw-bold'>Ids: </small>
                                        {data.transId}
                                        <span className='fw-bold ms-1 me-1'>|</span>
                                        {data.orderId}
                                    </span>
                                </div>
                                <div className="ms-2 me-auto">
                                    <div><small className='fw-bold'>Amount:</small> {data.amount}</div>
                                    <span>
                                        <small className='fw-bold'>UTR:</small>
                                        {data?.utr ? data?.utr?.number : data?.upiRefId}
                                        <span className='fw-bold ms-1 me-1'>|</span>
                                        {data?.utr?.using}
                                    </span>
                                </div>
                            </li>
                        ))
                    )}
                </ol>
            </Modal.Body>
        </Modal>
    )
}

export default SucceedOrdersModal