import React from 'react'

function PerPage({ limit, setLimit, perPageArr, totalRecords }) {
    return (
        <div className="row justify-content-end pagination-utility">
            <div className="col-lg-2 col-6">
                <label className="form-label">Total Records</label>
                <p className='mb-0 total-records'>{totalRecords}</p>
            </div>
            <div className="col-lg-2 col-6">
                <label htmlFor="perPage" className="form-label">Show Per Page</label>
                <select id="perPage" className="form-select" value={limit} onChange={(e) => setLimit(e.target.value)}>
                    <option value="" disabled>
                        Select Per Page
                    </option>
                    {perPageArr && !!perPageArr.length && (
                        perPageArr.map((page, key) => (
                            <option key={key} value={page}>{page}</option>
                        ))
                    )}
                </select>
            </div>
        </div>
    )
}

export default PerPage