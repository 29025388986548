import React from "react";
import { authTokenSelector } from "../recoil/atoms/userAtom";
import { useRecoilValue } from "recoil";
import { Navigate, Outlet } from "react-router-dom";
import { routes } from "./routes";

export default function ProtectedRoute() {
    const token = useRecoilValue(authTokenSelector) || false;
    return token ? <Outlet /> : <Navigate to={routes.login} />;
}
