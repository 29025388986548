
import React, { useEffect, useState, useRef } from 'react'
import axios from 'axios';
import * as Yup from "yup";
import toast from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment-timezone';
import ValidationError from '../components/ValidationError';
import useCustomValidation from '../hooks/useCustomValidation';
import { formatINRCurrency } from '../helpers/general';
import classNames from 'classnames';
import { statusCode } from '../enums/paymentStatusClient';

const interval = 1000

function Payment() {
    const [searchParams, setSearchParams] = useSearchParams();

    const transId = searchParams.get("transId")
    const payType = searchParams.get("payType")

    const orderStatusTimerRef = useRef(null)

    const schema = {
        transId: Yup.string().required("Transaction Id is required"),
        upiRefId: Yup
            .string()
            .matches(/^[0-9]+$/, "UTR Number must be only digits")
            .length(12, "UTR Number must be exactly 12 characters")
            .required("UTR number is required")
            .trim(),
        // .string()
        // .min(8, 'UTR Number must be at least 8 characters')
        // .max(16, 'UTR Number must be at most 16 characters')
        // .matches(/^[^.]*$/, {
        //     message: "UTR Number does not cointain period"
        // })
        // .matches(/^[^!@#$%^&*+=<>:;|~]*$/, {
        //     message: 'UTR Number does not cointain symbols'
        // })
        // .matches(/^[a-zA-Z0-9]+$/, "UTR Number must be only digits")
        // .required("UTR number is required")
        // .trim(),
    };
    const initial = {
        transId: null,
        upiRefId: null,
    };
    const { register, errors, handleSubmit, reset, setValue } =
        useCustomValidation({ schema, initial });

    const [orderStatus, setOrderStatus] = useState("");
    const [orderMsg, setOrderMsg] = useState("");
    const [orderAmt, setOrderAmt] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [timer, setTimer] = useState({ hours: null, minutes: null, seconds: null });
    const [upiOption, setUpiOption] = useState(null)
    const [neftOption, setNeftOption] = useState(null)
    const [selectedOption, setSelectedOption] = useState(null)

    const startCoundown = (time) => {
        // console.log({ time })
        const tz = moment.tz.guess();
        // console.log({ tz })
        const eventTime = moment(time).tz(tz).add(process.env.REACT_APP_ORDER_EXPIRY_MINUTES, "minutes");
        const currentTime = moment.tz(tz);

        // console.log({ eventTime })
        // console.log({ currentTime })

        // get duration between two times
        let duration = moment.duration(eventTime.diff(currentTime));
        // console.log({ duration })
        const intervalId = setInterval(function () {
            // get updated duration
            duration = moment.duration(duration - interval, 'milliseconds');

            // if duration is >= 0
            if (duration.asSeconds() <= 0) {

                clearInterval(intervalId);
                checkOrderStatus()
                // hide the countdown element
            } else {
                // otherwise, show the updated countdown
                const time = { hours: duration.hours(), minutes: duration.minutes(), seconds: duration.seconds() }
                setTimer(time)
            }
        }, interval);
    }

    const checkOrderStatus = async () => {
        await axios
            .post(`${process.env.REACT_APP_API_URL}/order/status`, {
                transId: transId,
            })
            .then((response) => {
                // console.log(response.data)
                if (response.status == 200) {
                    setValue("transId", transId)

                    setUpiOption(response.data.upi)
                    setNeftOption(response.data.neft)
                    setOrderMsg(response.data.msg)
                    setOrderStatus(response.data.status)
                    setOrderAmt(response.data.amount)
                    startCoundown(response.data.createdAt)
                } else {
                    setOrderMsg(response.msg)
                    setOrderStatus(response.data.status)
                }
            })
            .catch((error) => {
                const { response } = error
                if (response) {
                    const { data } = response
                    if (response?.status == 400) {
                        setOrderMsg(data.msg)
                        setOrderStatus(data.status)
                        if(data.status == statusCode.PROCESSING){
                            orderStatusTimerRef.current =  setTimeout(() => {
                                checkOrderStatus()
                            }, 5000);
                        }else if(data.status == statusCode.SUCCESS 
                            || data.status == statusCode.CANCELED 
                            || data.status == statusCode.FAILED
                            || data.status == statusCode.EXPIRED){
                                if(orderStatusTimerRef.current) clearTimeout(orderStatusTimerRef.current)
                        }
                    } else if (response?.status == 300) {
                        setOrderMsg(data.msg)
                        setOrderStatus(data.status)
                        if(orderStatusTimerRef.current) clearTimeout(orderStatusTimerRef.current)
                    } else {
                        setOrderMsg("Something went wrong!")
                        setOrderStatus(0)
                    }
                }
            })
            .finally((e) => {

            })
    }

    useEffect(() => {
        if (transId) {
            checkOrderStatus()
        }

        return () => {
            if(orderStatusTimerRef.current) clearTimeout(orderStatusTimerRef.current)
         }
    }, [transId])


    const refSubmit = async (data) => {
        if (payType == 'upi' || payType == 'neft') {
            setIsLoading(true)
            await axios
                .post(`${process.env.REACT_APP_API_URL}/order/submit`, {
                    transId: data.transId,
                    upiRefId: data.upiRefId,
                    using: payType,
                    refId: payType == 'upi' ? upiOption.id : neftOption.id
                })
                .then((response) => {
                    const { data } = response;
                    if (response.status == 200) {
                        toast.success(data.msg);
                        checkOrderStatus()
                    } else {
                        toast.error(data.msg);
                    }
                })
                .catch((error) => {
                    const { response } = error
                    if (response) {
                        const { data } = response
                        if (response?.status == 400) {
                            toast.error(data.msg);
                        } else {
                            toast.error(data.msg);
                        }
                    }
                })
                .finally((e) => {
                    setIsLoading(false)
                })
        }
    }

    const choosePayType = () => {
        if (selectedOption) {
            setSearchParams((params) => {
                params.delete('payType');
                params.append('payType', selectedOption);
                return params;
            });
            setSelectedOption(null)
        }
    }
    const goBackToList = () => {
        setSearchParams((params) => {
            params.delete('payType');
            return params;
        });
    }

    return (
        <div className="payment-page d-flex align-items-center justify-content-center">
            {transId && (
                (orderStatus == 2 || orderStatus == 3 || orderStatus == 4 || orderStatus == 5 || orderStatus == 6) && (
                    <div className="payment-box bg-light rounded-1">
                        <div className="message-box">
                            <img src={`assets/images/payment/icons/${orderStatus}.png`} />
                            <h4 className='mt-5'>{orderMsg}</h4>
                            <div>#<small> {transId}</small></div>
                        </div>
                    </div>
                ) || orderStatus == 1 && (
                    <div className="wrapper w-100 d-flex align-items-center justify-content-center h-100 p-2">
                        <div className="payment-box bg-light rounded-1">
                            <div className="payment-header bg-primary text-white d-flex flex-column flex-md-row  justify-content-between p-2">
                                <div>
                                    <b>Amount: </b>
                                    <span>{formatINRCurrency(orderAmt)}</span>
                                </div>
                                <div>
                                    <b>Transaction Id: </b>
                                    <span>{transId}</span>
                                </div>
                            </div>
                            <div className="payment-body p-2">
                                <div className="text-center timer">
                                    {timer.seconds != null && (
                                        <h4>
                                            {timer.minutes}
                                            <small> m </small>
                                            {timer.seconds}
                                            <small> s </small>
                                            <small> left </small>
                                        </h4>
                                    )}
                                </div>

                                {!payType && (
                                    <div className="payment-methods mt-3 mb-3">
                                        <h5>Payment Methods</h5>
                                        <ul className="list-group mt-4 mb-5">
                                            {upiOption && (
                                                <li onClick={() => setSelectedOption('upi')} className={classNames(
                                                    "list-group-item d-flex align-items-center justify-content-between rounded-3 mb-2 pointer",
                                                    { 'active': selectedOption == 'upi' })}>
                                                    <span className="fw-bold">
                                                        UPI
                                                        {/* <img className="ms-2 pay-icon" src="/assets/images/payment/upi.png" alt="upi" /> */}
                                                    </span>
                                                    <span className="badge rounded-pill">
                                                        <i className="ti ti-circle-check fs-8"></i>
                                                    </span>
                                                </li>
                                            )}
                                            {neftOption && (
                                                <li onClick={() => setSelectedOption('neft')} className={classNames(
                                                    "list-group-item d-flex align-items-center justify-content-between rounded-3 mt-2 pointer",
                                                    { 'active': selectedOption == 'neft' })} >
                                                    <span className="fw-bold">
                                                        IMPS Only
                                                        {/* <img className="ms-2 pay-icon" src="/assets/images/payment/neft.png" alt="neft" /> */}
                                                    </span>
                                                    <span className="badge rounded-pill">
                                                        <i className="ti ti-circle-check fs-8"></i>
                                                    </span>
                                                </li>
                                            )}
                                        </ul>
                                        <div className="mb-3 text-center pay-btn">
                                            {selectedOption && (
                                                <button className='btn btn-info' onClick={choosePayType}>Pay {formatINRCurrency(orderAmt)}</button>
                                            )}
                                        </div>
                                    </div>
                                )}

                                {payType == 'upi' && (
                                    <>
                                        <p className="mb-1 pb-1 border-bottom text-center">
                                            <small className="text-danger">
                                                For new transcations:<br />
                                                Don't use saved QRCODE or Old payment history,  You will face issues in getting deposit points and Company will not be responsible for this.<br />
                                                Please Do Payment using scanning of QRCODE only for Better Experience & Security. Thank you for your cooperation.
                                            </small>
                                        </p>
                                        <p className="mb-1 text-center">
                                            <small className="text-danger">
                                                नए अनुवादों के लिए:<br />
                                                सहेजे गए QRCODE या पुराने भुगतान इतिहास का उपयोग न करें, आपको जमा अंक प्राप्त करने में समस्याओं का सामना करना पड़ेगा और कंपनी इसके लिए जिम्मेदार नहीं होगी।<br />
                                                कृपया बेहतर अनुभव और सुरक्षा के लिए केवल QRCODE की स्कैनिंग का उपयोग करके भुगतान करें। आपके सहयोग के लिए धन्यवाद।
                                            </small>
                                        </p>
                                        <div className="qr-code-container text-center">
                                            <img src={`${process.env.REACT_APP_API_URL}/qrcode?transId=${transId}`} className='mt-2 mb-2' alt="QRCode" />
                                        </div>
                                    </>
                                )}

                                {payType == 'neft' && (
                                    <div className="row flex-column align-items-center">
                                        <div className="col-md-6 mb-2">
                                            <div className="form-group">
                                                <label htmlFor="upiRefId">Account Number</label>
                                                <input type="text" disabled value={neftOption.acNumber} className="form-control" />
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div className="form-group">
                                                <label htmlFor="upiRefId">IFSC Code</label>
                                                <input type="text" disabled value={neftOption.ifscCode} className="form-control" />
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div className="form-group">
                                                <label htmlFor="upiRefId">Account Holder</label>
                                                <input type="text" disabled value={neftOption.acHolderName} className="form-control" />
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div className="form-group">
                                                <label htmlFor="upiRefId">Bank Name</label>
                                                <input type="text" disabled value={neftOption.bankName} className="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {(payType == 'upi' || payType == 'neft') && (
                                    <>
                                        <form onSubmit={handleSubmit(refSubmit)}>
                                            <div className="row flex-column align-items-center">
                                                <div className="col-md-6">
                                                    <input {...register("transId")} type="text" hidden className="form-control" />
                                                    <div className="form-group">
                                                        <label htmlFor="payment-upiRefId" >UTR Number</label>
                                                        <input {...register("upiRefId")} type="text" className="form-control" id="payment-upiRefId" />
                                                        <ValidationError name="upiRefId" errors={errors} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mt-2">
                                                    <button type="submit" className="btn btn-info w-100"
                                                        disabled={isLoading}>
                                                        {isLoading && (
                                                            <div className="spinner-border"></div>
                                                        )} Submit
                                                    </button>
                                                </div>
                                                <div className="col-md-6 mt-1">
                                                    <button type="reset" className="btn btn-outline-link  w-100" onClick={goBackToList}>
                                                        Back
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                        <p className="mt-3 mb-1 pb-1 border-bottom text-center">
                                            <small className="text-primary">Click Submit Button when your amount is deducted from your account.</small>
                                        </p>
                                        <p className='text-center'>
                                            <small className="text-primary">जब आपके खाते से राशि काट ली जाए तो सबमिट बटन पर क्लिक करें।</small>
                                        </p>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                )
            )
            }
        </div >
    )
}

export default Payment