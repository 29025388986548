import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import toast from 'react-hot-toast';
import * as Yup from "yup";
import useCustomValidation from '../../../hooks/useCustomValidation';
import useLogout from '../../../hooks/useLogout';
import ValidationError from '../../ValidationError';

function UpdateUPIModal({ updateData, show, handleClose }) {
    const logoutUser = useLogout();

    const [isLoading, setIsLoading] = useState(false);
    const [upiActiveStatus, setUpiActiveStatus] = useState(null);

    const schema = {
        upi: Yup.string().required("UPI is required"),
        merchant: Yup.string().required("Merchant is required"),
        name: Yup.string().required("UPI Name is required").trim(),
        vpa: Yup.string().required("UPI Id is required").trim(),
        enabled: Yup.bool().required("Active is required"),
    };
    const initial = {
        upi: null,
        merchant: null,
        name: null,
        vpa: null,
        enabled: null,
    };
    const { register, errors, handleSubmit, reset, getValues, setValue } =
        useCustomValidation({ schema, initial });

    useEffect(() => {
        setValue("upi", updateData.upi)
        setValue("merchant", updateData.merchant)
        setValue("name", updateData.name)
        setValue("vpa", updateData.vpa)
        setValue("enabled", updateData.enabled)
        setUpiActiveStatus(updateData.enabled)

        return () => { }
    }, [updateData])

    const toggleActive = () => {
        const status = getValues("enabled")
        setValue("enabled", !status)
        setUpiActiveStatus(!status)
    }

    const updateUpiSubmit = async (data) => {
        setIsLoading(true)
        await axios
            .put(`${process.env.REACT_APP_API_URL}/merchant/upi/update`,
                { ...data, merchant: updateData.merchant })
            .then((response) => {
                const { data } = response;
                if (response.status == 200) {
                    toast.success(data.msg);
                    reset()
                    handleClose("success")
                } else {
                    toast.error(data.msg);
                }
            })
            .catch((error) => {
                const { response } = error
                if (response) {
                    const { data } = response
                    if (response?.status == 401) {
                        toast.error("Please Login!");
                        logoutUser();
                    } else if (response?.status == 400) {
                        toast.error(data.msg);
                    } else {
                        toast.error(data.msg || "Request failed!");
                    }
                }
            })
            .finally((e) => {
                setIsLoading(false)
            });
    }

    const handleOnHide = () => {
        reset()
        handleClose("manual")
    }

    return (
        upiActiveStatus != null && (
            <Modal show={show} onHide={handleOnHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Update UPI</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit(updateUpiSubmit)}>
                        <input type="text" {...register("upi")} hidden />
                        <input type="text" {...register("merchant")} hidden />

                        <div className="mb-3">
                            <label htmlFor='u-upi-name' className="form-label">UPI Name</label>
                            <input type="text" {...register("name")} className="form-control"
                                id="u-upi-name" />
                            <ValidationError name="name" errors={errors} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor='u-upi-vpa' className="form-label">UPI Id</label>
                            <input {...register("vpa")} type="text" className="form-control"
                                id="u-upi-vpa" />
                            <ValidationError name="vpa" errors={errors} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor='u-upi-active' className="form-label">Active</label>
                            <div className="form-check form-switch">
                                <input {...register("enabled")} value={upiActiveStatus} onChange={toggleActive} className="form-check-input" type="checkbox"
                                    id="u-upi-active" checked={upiActiveStatus} />
                                <label className="form-check-label" htmlFor="u-upi-active">UPI is {upiActiveStatus ? "Active" : "Inactive"}</label>
                            </div>
                            <ValidationError name="enabled" errors={errors} />
                        </div>

                        <button type="submit" className="btn btn-primary"
                            disabled={isLoading}>
                            {isLoading && (
                                <div className="spinner-border"></div>
                            )} Update
                        </button>
                    </form>
                </Modal.Body>
            </Modal>
        )
    )
}

export default UpdateUPIModal