import React from 'react'
import axios from 'axios'
import toast from 'react-hot-toast';
import moment from "moment-timezone"

import useLogout from '../../hooks/useLogout';

function StatementRecord({ statement, showSuccedOrders }) {
    const logoutUser = useLogout();

    const tz = moment.tz.guess();

    const convertToTimezone = (dateTime) => {
        return moment(dateTime).tz(tz).format("DD/MM/YYYY h:mm:ss A");
    }

    const downloadFile = async (id, name) => {
        await axios
            .post(
                `${process.env.REACT_APP_API_URL}/bankstatement/download`,
                { id: id },
                { responseType: 'blob' },
            )
            .then((response) => {
                if (response.status == 200) {
                    const fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    const fileLink = document.createElement('a');
                    fileLink.href = fileURL;

                    fileLink.setAttribute('download', name);
                    fileLink.setAttribute('target', '_blank');
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    fileLink.remove();
                } else {
                    toast.error("Request failed!");
                }
            })
            .catch((error) => {
                const { response } = error
                if (response) {
                    const { data } = response
                    // console.log(data)
                    if (response?.status == 401) {
                        toast.error("Please Login!");
                        logoutUser();
                    } else {
                        toast.error("Request failed!");
                    }
                }
            })


    }

    return (
        <tr>
            <td className="border-bottom-0">
                <p className="mb-0 fw-normal">{statement.merchant.name}</p>
            </td>
            <td className="border-bottom-0">
                <p className="mb-0 fw-normal">{statement.statementType?.name}</p>
            </td>
            <td className="border-bottom-0">
                <p className="mb-0 fw-normal underline" onClick={() => downloadFile(statement._id, statement.fileName)}>
                    <u className='pointer'>{statement.fileName}</u>
                </p>
            </td>
            <td className="border-bottom-0">
                <button className='btn btn-link m-1 text-dark' onClick={() => showSuccedOrders(statement.orders)}>
                    {statement?.orders?.length || 0}
                </button>
            </td>
            <td className="border-bottom-0">
                <p className="mb-0 fw-normal">{statement.user.name}</p>
            </td>
            <td className="border-bottom-0">
                <p className="mb-0 fw-normal">{convertToTimezone(statement.createdAt)}</p>
            </td>
        </tr>
    )
}

export default StatementRecord