import axios from 'axios';
import React, { useEffect, useMemo, useRef, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import toast from 'react-hot-toast';
import { useRecoilValue } from 'recoil';
import * as Yup from "yup";
import useLogout from '../../hooks/useLogout';
import { authTokenSelector } from '../../recoil/atoms/userAtom';
import useCustomValidation from '../../hooks/useCustomValidation';
import ValidationError from '../ValidationError';
import { jwtDecode } from 'jwt-decode';

function UserWhitelistModal({ updateData, show, handleClose }) {

    const token = useRecoilValue(authTokenSelector);
    const decodedUser = jwtDecode(token);
    const logoutUser = useLogout();

    const inputRef = useRef();

    const [isLoading, setIsLoading] = useState(false);
    const [whitelistUsers, setWhitelistUsers] = useState([]);

    const isWhitelistChanged = useMemo(() => {
        const isEqual = _.isEqual(updateData.whitelist, whitelistUsers)
        return !isEqual
    }, [updateData.whitelist, whitelistUsers])

    const schema = {
        id: Yup.string().required("Merchant Id is required"),
        whitelist: Yup.array(),
        owner: Yup.string().nullable(),
    };
    const initial = {
        id: null,
        whitelist: null,
        owner: null
    };
    const { register, errors, handleSubmit, reset, getValues, setValue } =
        useCustomValidation({ schema, initial });

    useEffect(() => {
        // console.log({updateData})

        let uniqueWhitelist = new Set(updateData.whitelist);

        setValue("id", updateData.merchant)
        setValue("whitelist", Array.from(uniqueWhitelist))
        setValue("owner", updateData.owner)

        setWhitelistUsers(Array.from(uniqueWhitelist))

        return () => { }
    }, [updateData])

    useEffect(() => {
        setValue("whitelist", whitelistUsers)
    }, [whitelistUsers])
    

    const handleKeyDown = (event) => {
        let inputValue = event.target.value
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
                let tempArr = inputValue.split(",")
                if(tempArr && tempArr.length > 1){
                    inputValue = tempArr.map(str =>{
                        return str.trim()
                    })
                    setWhitelistUsers((prev) => Array.from(new Set([...inputValue,...prev])));
                }else{
                    setWhitelistUsers((prev) => Array.from(new Set([inputValue,...prev])));
                }
                inputRef.current.value = ""
                event.preventDefault();
        }
    };
    const resetWhitelist = () => {
        let uniqueWhitelist = new Set(updateData.whitelist);
        setValue("whitelist", Array.from(uniqueWhitelist))
        setWhitelistUsers(Array.from(uniqueWhitelist))
    }
    const removeUser = (key) => {
        // console.log(key)
        let tempArr = _.clone(whitelistUsers)
        tempArr.splice(key, 1)
        // console.log(tempArr)
        setWhitelistUsers(tempArr)
    }

    const updateWhitelistSubmit = async (data) => {
        // console.log(data)
        setIsLoading(true)
        await axios
            .put(`${process.env.REACT_APP_API_URL}/merchant/update_whitelist`, data)
            .then((response) => {
                const { data } = response;
                if (response.status == 200) {
                    toast.success(data.msg);
                    reset()
                    handleClose("success")
                } else {
                    toast.error(data.msg);
                }
            })
            .catch((error) => {
                const { response } = error
                if (response) {
                    const { data } = response
                    if (response?.status == 401) {
                        toast.error("Please Login!");
                        logoutUser();
                    } else if (response?.status == 400) {
                        toast.error(data.msg);
                    } else {
                        toast.error(data.msg || "Request failed!");
                    }
                }
            })
            .finally((e) => {
                setIsLoading(false)
            });
    }

    const handleOnHide = () => {
        reset()
        handleClose("manual")
    }

    return (
        <Modal show={show} onHide={handleOnHide}>
            <Modal.Header closeButton>
                <Modal.Title>Whitelist Users</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(updateWhitelistSubmit)}>
                    <input type="text" {...register("id")} hidden />

                    <div className="mb-3">
                        <input type="text" onKeyDown={handleKeyDown} ref={inputRef} placeholder='Type username and press enter...' className="form-control"/>
                    </div>
                    
                    {whitelistUsers && !!whitelistUsers.length && (
                        <div className="mb-3 user-whitelist">
                            {whitelistUsers.map((user,key) =>(
                                <div className="ms-1 user" key={key}>
                                    <span>{user}</span>
                                    <div className='close' onClick={() => removeUser(key)}><i className="ti ti-x "></i></div>
                                </div>
                            ))}
                        </div>
                    )}
                    

                    <button type="submit" className="btn btn-primary"
                        disabled={isLoading}>
                        {isLoading && (
                            <div className="spinner-border"></div>
                        )} Update
                    </button>
                    <button type="button" className="btn btn-warning ms-2"
                        disabled={isLoading || !isWhitelistChanged}
                        onClick={resetWhitelist}>
                        Reset
                    </button>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default UserWhitelistModal