import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/css/styles.css"; // Add this line
import "./assets/css/custom.css"; // Add this line

import {
  RouterProvider,
} from "react-router-dom";
import { router } from "./router/routes";
import { CookiesProvider } from 'react-cookie';
import { AbilityContext } from './helpers/Can';
import { abilityRulesSelector } from './recoil/atoms/userAtom';
import { useRecoilValue } from 'recoil';
import { defineAbility } from './helpers/ability';

function App() {
  const abilityRules = useRecoilValue(abilityRulesSelector);
  const ability = defineAbility(abilityRules)
  
  return (
    <CookiesProvider defaultSetOptions={{ path: '/' }}>
      <AbilityContext.Provider value={ability}>
        <RouterProvider router={router} />
      </AbilityContext.Provider>
    </CookiesProvider>
  );
}

export default App;
