import React, { useState } from 'react'
import axios from 'axios';
import toast from 'react-hot-toast';

import useLogout from '../../hooks/useLogout';
import { statusCodeAdmin } from '../../enums/paymentStatusAdmin';
import { statusCode } from '../../enums/paymentStatusClient';
import { Can } from '../../helpers/Can';

const OrderOptions = ({ transId, amount, handleApproveOrderShow, getOrders, status, statusAdmin }) => {
    const logoutUser = useLogout();

    const [isLoading, setIsLoading] = useState(false);

    const cancelOrder = async () => {
        const params = {
            transId
        }

        setIsLoading(true)
        await axios
            .post(`${process.env.REACT_APP_API_URL}/order/cancel`, params)
            .then((response) => {
                const { data } = response;
                if (response.status == 200) {
                    toast.success(data.msg);
                    getOrders()
                } else {
                    toast.error(data.msg);
                }
            })
            .catch((error) => {
                const { response } = error
                if (response) {
                    const { data } = response
                    if (response?.status == 401) {
                        toast.error("Please Login!");
                        logoutUser();
                    } else if (response?.status == 400) {
                        toast.error(data.msg);
                    } else {
                        toast.error(data.msg || "Request failed!");
                    }
                }
            })
            .finally((e) => {
                setIsLoading(false)
            });

    }

    return (
        <div className="d-flex align-items-start manual-approve">
            {status == statusCode.PROCESSING && statusAdmin == statusCodeAdmin.UPI_REF_ADDED && (
                <Can I="approve" a="Order">
                    <button type="button" className="btn btn-primary btn-sm me-1" disabled={isLoading}
                        onClick={() => handleApproveOrderShow(transId, amount)}>Approve</button>
                </Can>
            )}

            {(statusAdmin == statusCodeAdmin.CREATED || statusAdmin == statusCodeAdmin.UPI_REF_ADDED) && (
                <Can I="cancel" a="Order">
                    <button type="button" className="btn btn-danger btn-sm" disabled={isLoading}
                        onClick={cancelOrder}>Cancel</button>
                </Can>
            )}
        </div>
    )
}

export default OrderOptions
