"use client";

import { useRecoilState } from "recoil";
import { Link } from "react-router-dom";
import { userAtom } from "../recoil/atoms/userAtom";
import { sidebarAtom } from "../recoil/atoms/themeAtoms";
import useLogout from "../hooks/useLogout";
import { routes } from "../router/routes";
import Dropdown from 'react-bootstrap/Dropdown';

export default function Header() {
  const [user, setUser] = useRecoilState(userAtom);
  const [showSidebar, setShowSidebar] = useRecoilState(sidebarAtom);

  const logoutUser = useLogout();

  return (
    <header className="app-header">
      <nav className="navbar navbar-expand-lg navbar-light">
        <ul className="navbar-nav">
          <li className="nav-item d-block d-xl-none">
            <a
              onClick={() => setShowSidebar(true)}
              className="nav-link sidebartoggler nav-icon-hover"
              id="headerCollapse"
            >
              <i className="ti ti-menu-2"></i>
            </a>
          </li>
        </ul>
        <div
          className="navbar-collapse justify-content-end px-0"
          id="navbarNav"
        >

          <ul className="navbar-nav flex-row ms-auto align-items-center justify-content-end">
            <Dropdown className="nav-item user-dropdown" as="div">
              <Dropdown.Toggle as="div" id="dropdown-basic" className="nav-link">
                <img
                  src="../assets/images/profile/user-1.jpg"
                  alt=""
                  width="35"
                  height="35"
                  className="rounded-circle"
                />
                <p className="mb-0 ms-2 text-dark">{user?.name}</p>
              </Dropdown.Toggle>

              <Dropdown.Menu>

                {/* <Dropdown.Item>
                  <Link
                    to={routes.changePasswordFull}
                    className="d-flex align-items-center gap-2 dropdown-item"
                  >
                    <i className="ti ti-lock fs-6"></i>
                    <p className="mb-0 fs-3">Change Password</p>
                  </Link>
                </Dropdown.Item> */}

                <Dropdown.Item onClick={logoutUser} as="div">
                  <a type="button" className="d-flex align-items-center gap-2 dropdown-item">
                    <i className="ti ti-logout fs-6"></i>
                    <p className="mb-0 fs-3">logout</p>
                  </a>
                </Dropdown.Item>

              </Dropdown.Menu>
            </Dropdown>

          </ul>
        </div>
      </nav>
    </header>
  );
}
