import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const useCustomValidation = (prop) => {
  const formSchema = Yup.object().shape(prop.schema);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    getValues
  } = useForm({
    defaultValues: prop.initial,
    resolver: yupResolver(formSchema),
  });

  return { register, errors, handleSubmit, reset, setValue, getValues };
};

export default useCustomValidation;
