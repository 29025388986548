const formatINRCurrency = (amt) => {
    if (isNaN(amt)) {
        return amt
    } else {
        return amt.toLocaleString('en-IN', {
            maximumFractionDigits: 2,
            style: 'currency',
            currency: 'INR'
        })
    }
}

const getSelectedText = (elem) => {
    if (elem.tagName === "TEXTAREA" ||
        (elem.tagName === "INPUT" && elem.type === "text")) {
        return elem.value.substring(elem.selectionStart,
            elem.selectionEnd);
    }
    return null;
}

module.exports = {
    formatINRCurrency,
    getSelectedText
}