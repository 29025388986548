import React, { useCallback, useMemo, useState } from 'react'
import moment from 'moment';
import { statusCode, statusTxt } from '../../enums/paymentStatusClient';
import { statusCodeAdmin, statusTxtAdmin } from '../../enums/paymentStatusAdmin';
import _ from 'lodash';

function OrderFilters({ filters, setFilters, setPage, merchants }) {
    const isFilter = useMemo(() => {
        return !!filters.date || !!filters.status || !!filters.statusAdmin
    }, [filters]);

    const changeDateFilter = (e) => {
        setFilters((prevCount) => { return { ...prevCount, date: e.target.value || "" } })
        setPage(1)
    }
    const clearDateFilter = () => {
        setFilters((prevCount) => { return { ...prevCount, date: "" } })
        setPage(1)
    }

    const changePayTypeFilter = (e) => {
        setFilters((prevCount) => { return { ...prevCount, payType: e.target.value || "" } })
        setPage(1)
    }
    const clearPayTypeFilter = () => {
        setFilters((prevCount) => { return { ...prevCount, payType: "" } })
        setPage(1)
    }

    const changeStatusFilter = (e) => {
        setFilters((prevCount) => { return { ...prevCount, status: e.target.value || "" } })
        setPage(1)
    }
    const clearStatusFilter = () => {
        setFilters((prevCount) => { return { ...prevCount, status: "" } })
        setPage(1)
    }

    const changeStatusAdminFilter = (e) => {
        setFilters((prevCount) => { return { ...prevCount, statusAdmin: e.target.value || "" } })
        setPage(1)
    }
    const clearStatusAdminFilter = () => {
        setFilters((prevCount) => { return { ...prevCount, statusAdmin: "" } })
        setPage(1)
    }

    const changeMerchantFilter = (e) => {
        setFilters((prevCount) => { return { ...prevCount, merchant: e.target.value || "" } })
        setPage(1)
    }
    const clearMerchantFilter = () => {
        setFilters((prevCount) => { return { ...prevCount, merchant: "" } })
        setPage(1)
    }

    const [searchStr, setSearchStr] = useState("")

    const searchDebounceFn = useCallback(_.debounce(handleSearchDebounceFn, 300), []);
    function handleSearchDebounceFn(value) {
        setFilters((prevCount) => { return { ...prevCount, search: value || "" } })
        setPage(1)
    }

    const changeSearchFilter = (e) => {
        setSearchStr(e.target.value)
        searchDebounceFn(e.target.value)
    }
    const clearSearchFilter = () => {
        setSearchStr("")
        setFilters((prevCount) => { return { ...prevCount, search: "" } })
        setPage(1)
    }

    const filterAllClear = () => {
        setSearchStr("")
        setFilters({ date: "", status: "", statusAdmin: "", merchant: "" })
        setPage(1)
    }

    return (
        <div className="row mb-2">
            <div className="col-lg-2 col-6">
                <label htmlFor="dateFilter" className="form-label">Date</label>
                <div className="input-group">
                    <input onChange={changeDateFilter} value={filters.date} id='dateFilter' type="date" className="form-control" placeholder="Filter Date"
                        max={moment().tz(moment.tz.guess()).format("YYYY-MM-DD")} />
                    {filters.date && (
                        <span className="input-group-text pointer" onClick={clearDateFilter}>
                            <i className="ti ti-x fs-4 text-danger"></i>
                        </span>
                    )}
                </div>
            </div>

            <div className="col-lg-2 col-6">
                <label htmlFor="paidUsingFilter" className="form-label">Paid Using</label>
                <div className="input-group">
                    <select id="paidUsingFilter" className="form-select" value={filters.payType} onChange={changePayTypeFilter}>
                        <option value="" disabled>-- Filters --</option>
                        <option value="upi">UPI</option>
                        <option value="neft">NEFT</option>
                    </select>
                    {filters.payType && (
                        <span className="input-group-text pointer" onClick={clearPayTypeFilter}>
                            <i className="ti ti-x fs-4 text-danger"></i>
                        </span>
                    )}
                </div>
            </div>

            <div className="col-lg-2 col-6">
                <label htmlFor="statusFilter" className="form-label">Client Status (CS)</label>
                <div className="input-group">
                    <select id="statusFilter" className="form-select" value={filters.status} onChange={changeStatusFilter}>
                        <option value="" disabled>-- Filters --</option>
                        <option value={statusCode.PENDING}>{statusTxt[statusCode.PENDING]}</option>
                        <option value={statusCode.PROCESSING}>{statusTxt[statusCode.PROCESSING]}</option>
                        <option value={statusCode.SUCCESS}>{statusTxt[statusCode.SUCCESS]}</option>
                        <option value={statusCode.FAILED}>{statusTxt[statusCode.FAILED]}</option>
                        <option value={statusCode.EXPIRED}>{statusTxt[statusCode.EXPIRED]}</option>
                        <option value={statusCode.CANCELED}>{statusTxt[statusCode.CANCELED]}</option>
                    </select>
                    {filters.status && (
                        <span className="input-group-text pointer" onClick={clearStatusFilter}>
                            <i className="ti ti-x fs-4 text-danger"></i>
                        </span>
                    )}
                </div>
            </div>

            <div className="col-lg-2 col-6 mt-1 mt-lg-0">
                <label htmlFor="statusAdminFilter" className="form-label">System Status (SS)</label>
                <div className="input-group">
                    <select id="statusAdminFilter" className="form-select" value={filters.statusAdmin} onChange={changeStatusAdminFilter}>
                        <option value="" disabled>-- Filters --</option>
                        <option value={statusCodeAdmin.CREATED}>{statusTxtAdmin[statusCodeAdmin.CREATED]}</option>
                        <option value={statusCodeAdmin.UPI_REF_ADDED}>{statusTxtAdmin[statusCodeAdmin.UPI_REF_ADDED]}</option>
                        <option value={statusCodeAdmin.QUEUE_ADDED}>{statusTxtAdmin[statusCodeAdmin.QUEUE_ADDED]}</option>
                        <option value={statusCodeAdmin.QUEUE_PROCESSING}>{statusTxtAdmin[statusCodeAdmin.QUEUE_PROCESSING]}</option>
                        <option value={statusCodeAdmin.QUEUE_FAILED}>{statusTxtAdmin[statusCodeAdmin.QUEUE_FAILED]}</option>
                        <option value={statusCodeAdmin.SUCCESS}>{statusTxtAdmin[statusCodeAdmin.SUCCESS]}</option>
                        <option value={statusCodeAdmin.FAILED}>{statusTxtAdmin[statusCodeAdmin.FAILED]}</option>
                        <option value={statusCodeAdmin.EXPIRED}>{statusTxtAdmin[statusCodeAdmin.EXPIRED]}</option>
                        <option value={statusCodeAdmin.CANCELED}>{statusTxtAdmin[statusCodeAdmin.CANCELED]}</option>
                    </select>
                    {filters.statusAdmin && (
                        <span className="input-group-text pointer" onClick={clearStatusAdminFilter}>
                            <i className="ti ti-x fs-4 text-danger"></i>
                        </span>
                    )}
                </div>
            </div>

            <div className="col-lg-2 col-6 mt-1 mt-lg-0">
                <label htmlFor="merchantFilter" className="form-label">Merchant</label>
                <div className="input-group">
                    <select id="merchantFilter" className="form-select" value={filters.merchant} onChange={changeMerchantFilter}>
                        <option value="" disabled>-- Filters --</option>
                        {merchants && merchants.length && (
                            merchants.map((merchant, key) => (
                                <option key={key} value={merchant._id}>{merchant.name}</option>
                            ))
                        )}
                    </select>
                    {filters.merchant && (
                        <span className="input-group-text pointer" onClick={clearMerchantFilter}>
                            <i className="ti ti-x fs-4 text-danger"></i>
                        </span>
                    )}
                </div>
            </div>

            <div className="col-lg-2 col-6">
                <label htmlFor="searchFilter" className="form-label">Search</label>
                <div className="input-group">
                    <input onChange={changeSearchFilter} value={searchStr} id='searchFilter' type="text" className="form-control" placeholder="UTR/Trans. Id/Order Id" />
                    {filters.search && (
                        <span className="input-group-text pointer" onClick={clearSearchFilter}>
                            <i className="ti ti-x fs-4 text-danger"></i>
                        </span>
                    )}
                </div>
            </div>

            <div className="col-lg-2 col-6 mt-1 mt-lg-0">
                {isFilter && (
                    <>
                        <label htmlFor='clear-all' className="form-label w-100">&nbsp;</label>
                        <button id='clear-all' className='btn btn-outline-link' onClick={filterAllClear}>Clear All Filters</button>
                    </>
                )}
            </div>

        </div>
    )
}

export default OrderFilters
