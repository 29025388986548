import { useState } from "react";

const usePagination = (props) => {
    const perPageArr = [5, 10, 15, 20, 50, 100]

    const [limit, setLimitInternal] = useState(props.limit || 10)
    const [page, setPage] = useState(props.page || 1)
    const [totalPage, setTotalPage] = useState()
    const [totalRecords, setTotalRecords] = useState()

    const setLimit = (limit) => {
        setPage(1)
        setLimitInternal(limit)
    }

    return {
        perPageArr,
        limit,
        setLimit,
        page,
        setPage,
        totalPage,
        setTotalPage,
        totalRecords,
        setTotalRecords,
    };
}

export default usePagination;