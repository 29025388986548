function Error404() {

    return (
        <div className="d-flex align-items-center error-404">
            <div className="row justify-content-center">
                <div className="col-lg-8 col-12">
                    <img src="/assets/images/404.jpg" className="img-fluid" alt="Error 404" />
                </div>
            </div>
        </div>
    )
}

export default Error404