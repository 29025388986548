import axios from 'axios';
import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import toast from 'react-hot-toast';
import * as Yup from "yup";
import useCustomValidation from '../../../hooks/useCustomValidation';
import useLogout from '../../../hooks/useLogout';
import ValidationError from '../../ValidationError';

function CreateNEFTModal({ createData, show, handleClose }) {
    const logoutUser = useLogout();

    const [isLoading, setIsLoading] = useState(false);

    const schema = {
        acNumber: Yup.string().required("Account Number is required").trim(),
        acHolderName: Yup.string().required("Account Holder Name is required").trim(),
        ifscCode: Yup.string().required("IFSC Code is required").trim(),
        bankName: Yup.string().required("Bank Name is required").trim(),
    };
    const initial = {
        acNumber: null,
        acHolderName: null,
        ifscCode: null,
        bankName: null,
    };
    const { register, errors, handleSubmit, reset, setValue } =
        useCustomValidation({ schema, initial });

    const createNewNeftSubmit = async (data) => {
        setIsLoading(true)
        await axios
            .post(`${process.env.REACT_APP_API_URL}/merchant/neft/create`,
                { ...data, merchant: createData.merchant })
            .then((response) => {
                const { data } = response;
                if (response.status == 201) {
                    toast.success(data.msg);
                    reset()
                    handleClose("success")
                } else {
                    toast.error(data.msg);
                }
            })
            .catch((error) => {
                const { response } = error
                if (response) {
                    const { data } = response
                    if (response?.status == 401) {
                        toast.error("Please Login!");
                        logoutUser();
                    } else if (response?.status == 400) {
                        toast.error(data.msg);
                    } else {
                        toast.error(data.msg || "Request failed!");
                    }
                }
            })
            .finally((e) => {
                setIsLoading(false)
            });

    }

    return (
        <Modal show={show} onHide={() => handleClose("manual")}>
            <Modal.Header closeButton>
                <Modal.Title>Create NEFT</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(createNewNeftSubmit)}>
                    <div className="mb-3">
                        <label htmlFor='c-neft-acNumber' className="form-label">Account Number</label>
                        <input type="text" {...register("acNumber")} className="form-control"
                            id="c-neft-acNumber" />
                        <ValidationError name="acNumber" errors={errors} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor='c-neft-acHolderName' className="form-label">Account Holder Name</label>
                        <input {...register("acHolderName")} type="text" className="form-control"
                            id="c-neft-acHolderName" />
                        <ValidationError name="acHolderName" errors={errors} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor='c-neft-ifscCode' className="form-label">IFSC Code</label>
                        <input {...register("ifscCode")} type="text" className="form-control"
                            id="c-neft-ifscCode" />
                        <ValidationError name="ifscCode" errors={errors} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor='c-neft-bankName' className="form-label">Bank Name</label>
                        <input {...register("bankName")} type="text" className="form-control"
                            id="c-neft-bankName" />
                        <ValidationError name="bankName" errors={errors} />
                    </div>

                    <button type="submit" className="btn btn-primary"
                        disabled={isLoading}>
                        {isLoading && (
                            <div className="spinner-border"></div>
                        )} Create
                    </button>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default CreateNEFTModal