import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  abilityRulesAtom,
  authTokenAtom,
  userAtom,
} from "../recoil/atoms/userAtom";
import { routes } from "../router/routes";

const useLogout = () => {
  const navigate = useNavigate();
  const [user, setUser] = useRecoilState(userAtom);
  const [authToken, setAuthToken] = useRecoilState(authTokenAtom);
  const [abilityRules, setAbilityRules] = useRecoilState(abilityRulesAtom);

  return () => {
    setAuthToken(null);
    setAbilityRules(null);
    setUser(null);
    navigate(routes.login);
  };
};

export default useLogout;
