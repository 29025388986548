import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios';
import Chart from "react-apexcharts";
import _ from 'lodash';
import toast from 'react-hot-toast';

import { AbilityContext, Can } from '../helpers/Can';
import useLogout from '../hooks/useLogout';


const Home = () => {
  const logoutUser = useLogout();
  const ability = useContext(AbilityContext);

  const [merchants, setMerchants] = useState(null)
  const [selectedMerchant, setSelectedMerchant] = useState(null)

  const [todayChart, setTodayChart] = useState({
    options: {
      color: "#adb5bd",
      chart: {
        type: "donut",
        fontFamily: "Plus Jakarta Sans', sans-serif",
        // foreColor: "#adb0bb",
      },
      plotOptions: {
        pie: {
          startAngle: 0,
          endAngle: 360,
          donut: {
            size: '75%',
          },
        },
      },
      // stroke: {
      //   show: false,
      // },

      // dataLabels: {
      //   enabled: false,
      // },

      legend: {
        show: false,
      },
      // success failed expired canceled pending processing
      colors: ["#198754", "#dc3545", "#6c757d", "#ffc107", "#212529", "#0d6efd"],

      responsive: [
        {
          breakpoint: 991,
          options: {
            chart: {
              width: 150,
            },
          },
        },
      ],
      tooltip: {
        theme: "dark",
        fillSeriesColor: false,
      },
      labels: ["Success", "Failed", "Expired", "Canceled", "Pending", "Processing"],
    },
    series: [],
  })

  const [countChartOptions, setCountChartOptions] = useState({
    chart: {
      id: "basic-bar",
      type: "bar",
      height: 345,
      offsetX: -15,
      toolbar: { show: true },
      foreColor: "#adb0bb",
      fontFamily: 'inherit',
      sparkline: { enabled: false },
    },
    // success failed expired canceled pending processing
    colors: ["#198754", "#dc3545", "#ffc107", "#212529", "#0d6efd"],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "35%",
        borderRadius: [6],
        borderRadiusApplication: 'end',
        borderRadiusWhenStacked: 'all'
      },
    },
    markers: { size: 0 },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    grid: {
      borderColor: "rgba(0,0,0,0.1)",
      strokeDashArray: 3,
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    xaxis: {
      type: "category",
      categories: [],
      labels: {
        style: { cssClass: "grey--text lighten-2--text fill-color" },
      }
    },
    yaxis: {
      show: true,
      tickAmount: 4,
      labels: {
        style: {
          cssClass: "grey--text lighten-2--text fill-color",
        },
      },
    },
    stroke: {
      show: true,
      width: 3,
      lineCap: "butt",
      colors: ["transparent"],
    },
    tooltip: { theme: "light" },
    responsive: [
      {
        breakpoint: 600,
        options: {
          plotOptions: {
            bar: {
              borderRadius: 3,
            }
          },
        }
      }
    ]
  })
  const [amountChartOptions, setAmountChartOptions] = useState({
    chart: {
      id: "basic-bar",
      type: "bar",
      height: 345,
      offsetX: -15,
      toolbar: { show: true },
      foreColor: "#adb0bb",
      fontFamily: 'inherit',
      sparkline: { enabled: false },
    },
    // success failed expired canceled pending processing
    colors: ["#198754", "#dc3545", "#ffc107", "#212529", "#0d6efd"],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "35%",
        borderRadius: [6],
        borderRadiusApplication: 'end',
        borderRadiusWhenStacked: 'all'
      },
    },
    markers: { size: 0 },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    grid: {
      borderColor: "rgba(0,0,0,0.1)",
      strokeDashArray: 3,
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    xaxis: {
      type: "category",
      categories: [],
      labels: {
        style: { cssClass: "grey--text lighten-2--text fill-color" },
      }
    },
    yaxis: {
      show: true,
      tickAmount: 4,
      labels: {
        formatter: function (value) {
          if (isNaN(value)) {
            return value
          } else {
            return value.toLocaleString('en-IN', {
              maximumFractionDigits: 2,
              style: 'currency',
              currency: 'INR'
            })
          }
        },
        style: {
          cssClass: "grey--text lighten-2--text fill-color",
        },
      },
    },
    stroke: {
      show: true,
      width: 3,
      lineCap: "butt",
      colors: ["transparent"],
    },
    tooltip: { theme: "light" },
    responsive: [
      {
        breakpoint: 600,
        options: {
          plotOptions: {
            bar: {
              borderRadius: 3,
            }
          },
        }
      }
    ]
  })
  const [countChartData, setCountChartData] = useState(null)
  const [amountChartData, setAmountChartData] = useState(null)

  useEffect(() => {

    const getMerchants = async () => {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/order/merchants`)
        .then((response) => {
          if (response.status == 200) {
            const { data } = response;
            setMerchants(data.merchants)
            if (data.merchants && data.merchants.length) {
              const defaultM = _.find(data.merchants, (o) => o.default == true)

              if (defaultM) {
                setSelectedMerchant(defaultM._id)
              } else {
                const firstM = _.head(data.merchants)
                if (firstM) setSelectedMerchant(firstM._id)
              }
            }
          } else {
            setMerchants(null)
          }
        })
        .catch((error) => {
          const { response } = error
          if (response) {
            if (response?.status == 401) {
              toast.error("Please Login!");
              logoutUser();
            }
          }
          setMerchants(null)
        })
    }
    if (ability && ability.can('read', 'Dashboard')) {
      getMerchants()
    }

    return () => { }
  }, [])


  useEffect(() => {

    const getAnalytics = async () => {
      let url = `${process.env.REACT_APP_API_URL}/dashboard/get_analytics?merchant=${selectedMerchant}`

      await axios
        .get(url)
        .then((response) => {
          if (response.status == 200) {
            const { data } = response;

            setTodayChart((prevState) => ({
              ...prevState,
              series: data.todayRatio,
            }))
            setAmountChartOptions((prevState) => ({
              ...prevState,
              xaxis: { ...prevState.xaxis, categories: data.lable, },
            }))
            setCountChartOptions((prevState) => ({
              ...prevState,
              xaxis: { ...prevState.xaxis, categories: data.lable, },
            }))
            setCountChartData([
              { name: "Success:", data: data.successCount },
              { name: "Failed:", data: data.failedCount },
              { name: "Canceled:", data: data.canceledCount },
              { name: "Pending:", data: data.pendingCount },
              { name: "Processing:", data: data.processingCount },
            ])
            setAmountChartData([
              { name: "Success:", data: data.successSum },
              { name: "Failed:", data: data.failedSum },
              { name: "Canceled:", data: data.canceledSum },
              { name: "Pending:", data: data.pendingSum },
              { name: "Processing:", data: data.processingSum },
            ])
          } else {
          }
        })
        .catch((error) => {
          const { response } = error
          if (response) {
            if (response?.status == 401) {
              toast.error("Please Login!");
              logoutUser();
            }
          }
        })
    }

    if (selectedMerchant)
      getAnalytics()
    return () => {

    }
  }, [selectedMerchant])



  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title fw-semibold mb-4">Home</h5>

        <Can I="read" a="Dashboard">
          <div className="row">
            <div className="col-lg-12">
              <div className='mb-3 analytics-merchant'>
                {selectedMerchant && (
                  <select className="form-select" onChange={(e) => setSelectedMerchant(e.target.value)}
                    value={selectedMerchant}>
                    {merchants && merchants.length && (
                      merchants.map((merchant, key) => (
                        <option key={key} value={merchant._id}>
                          {merchant.name}
                        </option>
                      ))
                    )}
                  </select>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-8 d-flex align-items-strech">
              <div className="card w-100">
                <div className="card-body">
                  <div className="d-sm-flex d-block align-items-center justify-content-between mb-9">
                    <div className="mb-3 mb-sm-0">
                      <h5 className="card-title fw-semibold">Transaction Counts</h5>
                    </div>
                  </div>
                  {countChartData && (
                    <Chart
                      options={countChartOptions}
                      series={countChartData}
                      type="bar"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-4 d-flex align-items-strech">
              <div className="card w-100">
                <div className="card-body">
                  <div className="d-sm-flex d-block align-items-center justify-content-between mb-9">
                    <div className="mb-3 mb-sm-0">
                      <h5 className="card-title fw-semibold">Transaction Ratio (Today)</h5>
                    </div>
                  </div>
                  {todayChart.series && !!todayChart.series.length && (
                    <Chart
                      options={todayChart.options}
                      series={todayChart.series}
                      type="donut"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-8 d-flex align-items-strech">
              <div className="card w-100">
                <div className="card-body">
                  <div className="d-sm-flex d-block align-items-center justify-content-between mb-9">
                    <div className="mb-3 mb-sm-0">
                      <h5 className="card-title fw-semibold">Transaction Amounts</h5>
                    </div>
                  </div>
                  {amountChartData && (
                    <Chart
                      options={amountChartOptions}
                      series={amountChartData}
                      type="bar"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </Can>
      </div>
    </div>
  )
}

export default Home