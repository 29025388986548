import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import toast from 'react-hot-toast';
import * as Yup from "yup";
import _ from 'lodash';

import useCustomValidation from '../../hooks/useCustomValidation';
import useLogout from '../../hooks/useLogout';
import ValidationError from '../ValidationError';

function UploadStatementModal({ show, handleClose, merch, selectedMerch }) {
    const logoutUser = useLogout();

    const [isLoading, setIsLoading] = useState(false);
    const [merchants, setMerchants] = useState(merch)
    const [selectedMerchant, setSelectedMerchant] = useState(selectedMerch)

    const MAX_FILE_SIZE = 10 * 1024 * 1024; //1MB

    const validFileExtensions = { docs: ['csv', 'xls', 'xlsx'] };

    const isValidFileType = (fileName, fileType) => {
        return fileName && validFileExtensions[fileType].indexOf(fileName.split('.').pop()) > -1;
    }

    const schema = {
        merchant: Yup.string().required("Merchant is required"),
        statementType: Yup.string().required("Statement Type is required"),
        statement: Yup
            .mixed()
            .required("Required")
            .test("is-valid-type", "Not a valid file type",
                (value) => {
                    if (value && value.length)
                        return isValidFileType(value[0] && value[0].name.toLowerCase(), "docs")
                })
            .test("is-valid-size", "Max allowed size is 1MB",
                (value) => {
                    if (value && value.length)
                        return value[0] && value[0].size <= MAX_FILE_SIZE
                })
    };
    const initial = {
        merchant: null,
        statementType: null,
        statement: null,
    };
    const { register, errors, handleSubmit, reset, setValue } =
        useCustomValidation({ schema, initial });


    const updateMerchant = (e) => {
        setValue("statementType", null)
        setSelectedMerchant(e.target.value)
    }

    const statementTypes = useMemo(() => {
        const merchant = merchants?.find((merchant) => merchant._id === selectedMerchant)
        return merchant?.statementTypes
    }, [selectedMerchant])

    useEffect(() => {
        if (statementTypes && statementTypes.length) {
            const defaultStType = statementTypes?.find((stType) => stType.default === true)
            if (defaultStType) {
                setValue("statementType", defaultStType._id)
            }
        }
        return () => { }
    }, [statementTypes])


    useEffect(() => {
        if (show) {
            setMerchants(merch)
            setSelectedMerchant(selectedMerch)
            setValue("merchant", selectedMerch)
        }

        return () => { }
    }, [show])

    const uploadBankStatementSubmit = async (data) => {
        if (data.statement && data.statement.length) {
            setIsLoading(true)

            const formData = new FormData();
            formData.append("statement", data.statement[0]);
            formData.append("merchant", data.merchant);
            formData.append("statementType", data.statementType);

            await axios
                .post(
                    `${process.env.REACT_APP_API_URL}/bankstatement/upload`,
                    formData,
                    {
                        onUploadProgress: (progressEvent) => {
                            var percentCompleted = Math.round(
                                (progressEvent.loaded * 100) / progressEvent.total
                            );
                            // console.log(percentCompleted);
                        },
                        headers: {
                            "Content-Type": "multipart/form-data"
                        },
                    })
                .then((response) => {
                    const { data } = response;
                    if (response.status == 200) {
                        toast.success(data.msg);
                        reset()
                        setSelectedMerchant(null)
                        handleClose("success")
                    } else {
                        toast.error(data.msg);
                    }
                })
                .catch((error) => {
                    const { response } = error
                    if (response) {
                        const { data } = response
                        if (response?.status == 401) {
                            toast.error("Please Login!");
                            logoutUser();
                        } else if (response?.status == 400) {
                            toast.error(data.msg);
                        } else {
                            toast.error(data.msg || "Request failed!");
                        }
                    }
                })
                .finally((e) => {
                    setIsLoading(false)
                });
        }

    }

    const onHideHandle = () => {
        reset()
        setSelectedMerchant(null)
        handleClose("manual")
    }

    return (
        <Modal show={show} onHide={() => onHideHandle()}>
            <Modal.Header closeButton>
                <Modal.Title>Upload Statement</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(uploadBankStatementSubmit)}>
                    <div className="mb-3">
                        <label htmlFor='upload-stat-merchant' className="form-label">Merchant</label>
                        <select
                            {...register("merchant")}
                            className="form-select"
                            aria-label="Default select example"
                            id="upload-stat-merchant"
                            onChange={updateMerchant}
                        >
                            <option value="" disabled>
                                Select Merchant
                            </option>
                            {merchants &&
                                merchants.length &&
                                merchants.map((val, key) => (
                                    <option
                                        value={val._id}
                                        key={key}
                                    >
                                        {val.name}
                                    </option>
                                ))}
                        </select>
                        <ValidationError name="merchant" errors={errors} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor='upload-stat-statementType' className="form-label">Statement Type</label>
                        <select
                            {...register("statementType")}
                            className="form-select"
                            aria-label="Default select example"
                            id="upload-stat-statementType"
                        >
                            <option value="" disabled>
                                {statementTypes ? "Select Statement Type" : "Select Merchant First"}
                            </option>
                            {statementTypes &&
                                statementTypes.length &&
                                statementTypes.map((val, key) => (
                                    <option
                                        value={val._id}
                                        key={key}
                                    >
                                        {val.name}
                                    </option>
                                ))}
                        </select>
                        <ValidationError name="statementType" errors={errors} />
                    </div>

                    <div className="mb-3">
                        <label htmlFor='upload-stat-file' className="form-label">Statement File</label>
                        <input type="file" {...register("statement")}
                            name="statement"
                            accept="text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            multiple={false}
                            className="form-control" id="upload-stat-file" />
                        <ValidationError name="statement" errors={errors} />
                    </div>

                    <button type="submit" className="btn btn-primary"
                        disabled={isLoading}>
                        {isLoading && (
                            <div className="spinner-border"></div>
                        )} Create
                    </button>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default UploadStatementModal