import axios from 'axios';
import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import toast from 'react-hot-toast';
import { useRecoilValue } from 'recoil';
import * as Yup from "yup";
import useCustomValidation from '../../hooks/useCustomValidation';
import useLogout from '../../hooks/useLogout';
import { authTokenSelector } from '../../recoil/atoms/userAtom';
import ValidationError from '../ValidationError';
import { jwtDecode } from 'jwt-decode';
import { useEffect } from 'react';

function CreateMerchantModal({ show, handleClose }) {

    const token = useRecoilValue(authTokenSelector);
    const decodedUser = jwtDecode(token);

    const logoutUser = useLogout();

    const [isLoading, setIsLoading] = useState(false);
    const [userList, setUserList] = useState(null);

    const schema = {
        name: Yup.string().required("Name is required").trim(),
        min: Yup.number().required("Min. Amount is required"),
        max: Yup.number().required("Max. Amount is required").moreThan(Yup.ref("min")),
        callBackUrl: Yup.string().url().required(),
        owner: Yup.string().nullable(),
    };
    const initial = {
        name: null,
        min: null,
        max: null,
        callBackUrl: null,
        owner: null
    };
    const { register, errors, handleSubmit, reset, setValue } =
        useCustomValidation({ schema, initial });

    useEffect(() => {
        const getUserList = async () => {
            await axios
                .get(`${process.env.REACT_APP_API_URL}/merchant/user_list`)
                .then((response) => {
                    if (response.status == 200) {
                        const { data } = response;
                        setUserList(data.users)
                    } else {
                        setUserList(null)
                    }
                })
                .catch((error) => {
                    const { response } = error
                    if (response) {
                        const { data } = response
                        if (response?.status == 401) {
                            toast.error("Please Login!");
                            logoutUser();
                        } else if (response?.status == 400) {
                            setUserList(null)
                            toast.error(data.msg);
                        } else {
                            setUserList(null)
                            toast.error(data.msg || "Request failed!");
                        }
                    }
                })
                .finally((e) => {

                });
        }

        if (decodedUser && (decodedUser?.role == 'su' || decodedUser?.role == 'admin')) {
            getUserList()
        }
        return () => {

        }
    }, [])


    const createNewMerchantSubmit = async (data) => {
        setIsLoading(true)
        await axios
            .post(`${process.env.REACT_APP_API_URL}/merchant/create`, data)
            .then((response) => {
                const { data } = response;
                if (response.status == 201) {
                    toast.success(data.msg);
                    reset()
                    handleClose("success")
                } else {
                    toast.error(data.msg);
                }
            })
            .catch((error) => {
                const { response } = error
                if (response) {
                    const { data } = response
                    if (response?.status == 401) {
                        toast.error("Please Login!");
                        logoutUser();
                    } else if (response?.status == 400) {
                        toast.error(data.msg);
                    } else {
                        toast.error(data.msg || "Request failed!");
                    }
                }
            })
            .finally((e) => {
                setIsLoading(false)
            });

    }

    const handleOnHide = () => {
        reset()
        handleClose("manual")
    }

    return (
        <Modal show={show} onHide={handleOnHide}>
            <Modal.Header closeButton>
                <Modal.Title>Create Merchant</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(createNewMerchantSubmit)}>
                    <div className="mb-3">
                        <label htmlFor='c-merchant-name' className="form-label">Name</label>
                        <input type="text" {...register("name")} className="form-control"
                            id="c-merchant-name" />
                        <ValidationError name="name" errors={errors} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor='c-merchant-min' className="form-label">Min. Amount</label>
                        <input type="number" {...register("min")} className="form-control"
                            id="c-merchant-min" />
                        <ValidationError name="min" errors={errors} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor='c-merchant-max' className="form-label">Max. Amount</label>
                        <input type="number" {...register("max")} className="form-control"
                            id="c-merchant-max" />
                        <ValidationError name="max" errors={errors} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor='c-merchant-callback' className="form-label">Callback Url</label>
                        <input type="string" {...register("callBackUrl")} className="form-control"
                            id="c-merchant-callback" />
                        <ValidationError name="callBackUrl" errors={errors} />
                    </div>

                    {decodedUser && (decodedUser?.role == 'su' || decodedUser?.role == 'admin') && (
                        <div className="mb-3">
                            <label htmlFor='c-merchant-owner' className="form-label">Owner <small>(optional)</small></label>
                            <select id="c-merchant-owner" className="form-select" {...register("owner")}>
                                <option value="" disabled>
                                    Select Owner
                                </option>
                                {userList && !!userList.length && (
                                    userList.map((user, key) => (
                                        <option key={key} value={user._id}>{user.name}</option>
                                    ))
                                )}
                            </select>
                            <ValidationError name="owner" errors={errors} />
                        </div>
                    )}

                    <button type="submit" className="btn btn-primary"
                        disabled={isLoading}>
                        {isLoading && (
                            <div className="spinner-border"></div>
                        )} Create
                    </button>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default CreateMerchantModal