const statusCode = {
    PENDING: 1,
    PROCESSING: 2,
    SUCCESS: 3,
    FAILED: 4,
    EXPIRED: 5,
    CANCELED: 6,
}

const statusTxt = {
    1: "Pending",
    2: "Processing",
    3: "Succeed",
    4: "Failed",
    5: "Expired",
    6: "Canceled",
}


module.exports = { statusCode, statusTxt }