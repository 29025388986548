const statusCodeAdmin = {
    CREATED: 1,
    UPI_REF_ADDED: 2,
    QUEUE_ADDED: 3,
    QUEUE_PROCESSING: 4,
    QUEUE_FAILED: 5,
    SUCCESS: 6,
    FAILED: 7,
    EXPIRED: 8,
    CANCELED: 9,
}

const statusTxtAdmin = {
    1: "Created",
    2: "UTR Added",
    3: "Queue Added",
    4: "Queue Processing",
    5: "Queue Failed",
    6: "Succeed",
    7: "Failed",
    8: "Expired",
    9: "Canceled",
}

module.exports = { statusCodeAdmin, statusTxtAdmin }