import classNames from 'classnames'
import React from 'react'
import moment from "moment-timezone"

import { statusCode, statusTxt } from '../../enums/paymentStatusClient'
import { statusCodeAdmin, statusTxtAdmin } from '../../enums/paymentStatusAdmin'
import OrderOptions from './OrderOptions'
import { formatINRCurrency } from '../../helpers/general'

function OrderRecord({ order, getOrders, handleApproveOrderShow }) {
    const tz = moment.tz.guess();

    const convertToTimezone = (dateTime) => {
        return moment(dateTime).tz(tz).format("DD/MM/YYYY h:mm:ss A");
    }
    return (
        <tr>
            <td className="border-bottom-0">
                <div className='flex'>
                    <p className="mb-0 fw-bold" data-bs-toggle="tooltip" title="Order Id"><span className='text-dark'>O:</span> {order.orderId}</p>
                    <p className="mb-0 mt-1 fw-normal" data-bs-toggle="tooltip" title="Transaction Id"><span className='text-dark'>T:</span>  {order.transId}</p>
                    <p className="mb-0 mt-1 fw-normal" data-bs-toggle="tooltip" title="Merchant Name"><span className='text-dark'>M:</span>  {order?.merchant?.name}</p>
                </div>
            </td>
            <td className="border-bottom-0">
                <p className="mb-0 fw-normal" data-bs-toggle="tooltip" title="Amount"><span className='text-dark'>Amt:</span> {formatINRCurrency(order.amount)}</p>
                <p className="mb-0 mt-1 fw-normal" data-bs-toggle="tooltip" title="User Name"><span className='text-dark'>User:</span> {order.uname}</p>
            </td>
            <td className="border-bottom-0">
                <p className="mb-0 fw-normal">{order?.utr ? order?.utr?.number : order?.upiRefId}</p>
                <p className="mb-0 mt-1 fw-normal">{order?.utr ? order?.utr?.using : ''}</p>
                <p className="mb-0 mt-1 fw-normal" data-bs-toggle="tooltip" title="Processed By">
                    {order?.updatedBy?.name && (<>
                        <span className='text-dark'>PB:</span>{order?.updatedBy?.name}
                    </>)}
                </p>
            </td>
            <td className="border-bottom-0">
                <div className="flex">
                    <p data-bs-toggle="tooltip" title="Client Status"
                        className={classNames("mb-0 fw-normal",
                            {
                                'text-dark': order.status == statusCode.PENDING,
                                'text-success': order.status == statusCode.SUCCESS,
                                'text-primary': order.status == statusCode.PROCESSING,
                                'text-danger': (order.status == statusCode.FAILED)
                                    || (order.status == statusCode.CANCELED),
                                'text-secondary': order.status == statusCode.EXPIRED,
                            })}><span className='text-dark'>CS:</span> {statusTxt[order.status]}</p>

                    <p data-bs-toggle="tooltip" title="System Status"
                        className={classNames("mb-0 mt-1 fw-normal",
                            {
                                'text-dark': order.statusAdmin == (statusCodeAdmin.CREATED || statusCodeAdmin.UPI_REF_ADDED),
                                'text-success': order.statusAdmin == statusCodeAdmin.SUCCESS,
                                'text-danger': (order.statusAdmin == statusCodeAdmin.FAILED)
                                    || (order.statusAdmin == statusCodeAdmin.CANCELED),
                                'text-warning': order.statusAdmin == statusCodeAdmin.QUEUE_FAILED,
                                'text-info': order.statusAdmin == statusCodeAdmin.QUEUE_ADDED,
                                'text-primary': order.statusAdmin == statusCodeAdmin.QUEUE_PROCESSING,
                                'text-secondary': order.statusAdmin == statusCodeAdmin.EXPIRED,
                            })}><span className='text-dark'>SS:</span> {statusTxtAdmin[order.statusAdmin]}</p>

                    <p className="mb-0 fw-normal mt-1">Attampts : {order.attempts}</p>
                </div>
            </td>
            <td className="border-bottom-0">
                <p className="mb-0 fw-normal">{convertToTimezone(order.createdAt)}</p>
            </td>
            <td className="border-bottom-0">
                <OrderOptions handleApproveOrderShow={handleApproveOrderShow} transId={order.transId} amount={order.amount} getOrders={getOrders} status={order.status} statusAdmin={order.statusAdmin} />
            </td>
        </tr >
    )
}

export default OrderRecord