import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import classNames from "classnames";
import * as Yup from "yup";
import axios from "axios";

import {
    abilityRulesAtom,
    authTokenAtom,
    authTokenSelector,
    userAtom,
} from "../recoil/atoms/userAtom";
import useTogglePassword from "../hooks/useTogglePassword";
import useCustomValidation from "../hooks/useCustomValidation";
import ValidationError from "../components/ValidationError";
import { routes } from "../router/routes";

export default function Login() {
    const navigate = useNavigate();

    const { showPassword, togglePassword } = useTogglePassword();
    const token = useRecoilValue(authTokenSelector);
    const [user, setUser] = useRecoilState(userAtom);
    const [authToken, setAuthToken] = useRecoilState(authTokenAtom);
    const [abilityRules, setAbilityRules] = useRecoilState(abilityRulesAtom);

    useEffect(() => {
        if (token) {
            navigate(routes.homeFull);
        }
    }, [token]);

    const schema = {
        username: Yup.string().required("Username is required"),
        password: Yup.string().required("Password is required"),
    };
    const initial = {
        username: "",
        password: "",
    };
    const { register, errors, handleSubmit, reset, setValue } =
        useCustomValidation({ schema, initial });

    const [isLoading, setIsLoading] = useState(false);

    const onLogin = async (data) => {
        setIsLoading(true);
        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}/auth/signin`,
            data: {
                username: data.username,
                password: data.password,
            },
        })
            .then((response) => {
                if (response.status == 200) {
                    const { data } = response
                    setAuthToken(data.token);
                    setAbilityRules(data.abilityRules)
                    setUser({ name: data.name });
                    navigate(routes.homeFull);
                } else {
                    toast.error(response.msg);
                }
            })
            .catch((error) => {
                toast.error("Request failed!");
            })
            .finally((e) => {
                setIsLoading(false);
            });
    };
    return (
        <div
            className="page-wrapper"
            id="main-wrapper"
            data-layout="vertical"
            data-navbarbg="skin6"
            data-sidebartype="full"
            data-sidebar-position="fixed"
            data-header-position="fixed"
        >
            <div className="position-relative overflow-hidden radial-gradient min-vh-100 d-flex align-items-center justify-content-center">
                <div className="d-flex align-items-center justify-content-center w-100">
                    <div className="row justify-content-center w-100">
                        <div className="col-md-8 col-lg-6 col-xxl-3">
                            <div className="card mb-0">
                                <div className="card-body">
                                    <a className="text-nowrap logo-img text-center d-block py-3 w-100">
                                        <h3>Payment Panel</h3>
                                    </a>
                                    {/* <p className="text-center">Your Social Campaigns</p> */}
                                    <form autoComplete="off" onSubmit={handleSubmit(onLogin)}>
                                        <div className="mb-3">
                                            <label
                                                htmlFor="login-username"
                                                className="form-label"
                                            >
                                                Username
                                            </label>
                                            <input
                                                {...register("username", {
                                                    required: "This input is required.",
                                                })}
                                                type="text"
                                                className="form-control"
                                                id="login-username"
                                            />
                                            <ValidationError name="username" errors={errors} />
                                        </div>
                                        <div className="mb-4">
                                            <label htmlFor="login-password" className="form-label">
                                                Password
                                            </label>
                                            <div className="input-group">
                                                <input
                                                    {...register("password", {
                                                        required: "This input is required.",
                                                    })}
                                                    type={showPassword ? "text" : "password"}
                                                    className="form-control"
                                                    id="login-password"
                                                />
                                                <button
                                                    className="btn btn-outline-dark"
                                                    type="button"
                                                    onClick={togglePassword}
                                                >
                                                    <span>
                                                        <i
                                                            className={classNames("ti", {
                                                                "ti-eye": !showPassword,
                                                                "ti-eye-off": showPassword,
                                                            })}
                                                        ></i>
                                                    </span>
                                                </button>
                                            </div>
                                            <ValidationError name="password" errors={errors} />
                                        </div>
                                        <button
                                            type="submit"
                                            disabled={isLoading}
                                            className="btn btn-primary w-100 py-8 fs-4 mb-4 rounded-2"
                                        >
                                            {(isLoading && (
                                                <div className="spinner-border"></div>
                                            )) || <span>Sign In</span>}
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
