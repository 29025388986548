import { atom, selector } from "recoil";
import { recoilPersist } from "recoil-persist";
const { persistAtom } = recoilPersist({
    key: "recoil-persist", // this key is using to store data in local storage
    storage: localStorage, // configure which storage will be used to store the data
    converter: JSON, // configure how values will be serialized/deserialized in storage
});

// ATOMS
export const userAtom = atom({
    key: "user",
    default: null,
    effects_UNSTABLE: [persistAtom],
});

export const authTokenAtom = atom({
    key: "authToken",
    default: null,
    effects_UNSTABLE: [persistAtom],
});

export const abilityRulesAtom = atom({
    key: "abilityRules",
    default: null,
    effects_UNSTABLE: [persistAtom],
});

// SELECTORS
export const userSelector = selector({
    key: "userSelector",
    get: ({ get }) => {
        const user = get(userAtom);
        return user;
    },
});

export const authTokenSelector = selector({
    key: "authTokenSelector",
    get: ({ get }) => {
        const token = get(authTokenAtom);
        return token;
    },
});

export const abilityRulesSelector = selector({
    key: "abilityRulesSelector",
    get: ({ get }) => {
        const rules = get(abilityRulesAtom);
        return rules;
    },
});
