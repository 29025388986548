import axios from 'axios'
import React, { useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast';
import moment from 'moment';
import { useAbility } from '@casl/react';

import OrderRecord from '../components/order/OrderRecord';
import Paginator from '../components/pagination/Paginator';
import PerPage from '../components/pagination/PerPage';
import useLogout from '../hooks/useLogout';
import usePagination from '../hooks/usePagination';
import OrderFilters from '../components/order/OrderFilters';
import ApproveOrderModal from '../components/order/ApproveOrderModal';
import { AbilityContext } from '../helpers/Can';
import useRedirectToHome from '../hooks/useRedirectToHome';
import useSWR from 'swr';

const Orders = () => {
  const logoutUser = useLogout();
  const redirectToHome = useRedirectToHome();
  const ability = useAbility(AbilityContext);

  useEffect(() => {
    // console.log(ability, ability.cannot('read', 'Order'))
    if (ability && ability.cannot('read', 'Order')) {
      redirectToHome()
    }
    return () => { }
  }, [ability])

  const { limit, setLimit, perPageArr, page, setPage, totalPage, setTotalPage, totalRecords, setTotalRecords } = usePagination({ limit: 10 })

  const [orders, setOrders] = useState(null)
  const [merchants, setMerchants] = useState(null)
  const [filters, setFilters] = useState({
    date: moment().tz(moment.tz.guess()).format("YYYY-MM-DD"),
    payType: "",
    status: "",
    statusAdmin: "",
    merchant: "",
    search: "",
  })
  const [query, setQuery] = useState(null)

  const BASE_URL = `${process.env.REACT_APP_API_URL}/order/all`
  const [endpoint, setEndpoint] = useState(`${process.env.REACT_APP_API_URL}/order/all`)

  const querystring = useMemo(() => {
    let qu = ""
    let key = 0
    for (const property in query) {
      // console.log(`${key}: ${property}: ${query[property]}`);
      if(query[property]){
        qu+= `${key==0 ? '?' : '&'}${property}=${query[property]}`
        key++
      }
    }
    return qu
  }, [query])

  const swrFetchData = async (url, timeout = 10000) => {
    const response = await axios.get(url, { timeout: timeout });
    return response.data;
  };

  const {
    data: swrResponse,
    mutate: swrMutate,
    error: swrError,
  } = useSWR(
    [
      `${endpoint}/${querystring}`
    ],
    querystring
    ? ([url, params]) => swrFetchData(url, params)
    : null,
    {
      refreshInterval: 10000,
      dedupingInterval: 100,
      errorRetryInterval: 2000,
      revalidateOnMount: true,
      onSuccess: (data, key, config) => {
        // console.log("Entered onSuccess");
        // console.log(data); //This always prints undefined.
        setOrders(data?.orders)
        setTotalRecords(data?.pagination?.totalRecords)
        setTotalPage(data?.pagination?.totalPage)
        setPage(data?.pagination?.currentPage)
      },
      onError: (err, key, config) => {
        // console.log("Entered onError");
        // console.log(err); //This always prints undefined.
        if (err?.response?.status == 401) {
          toast.error("Please Login!");
            logoutUser();
        }
        setOrders(null)
        setTotalRecords(0)
        setTotalPage(0)
        setPage(1)
      },
    }
  );
  

  let intervalId

  // const getOrders = async () => {

  //   let url = `${process.env.REACT_APP_API_URL}/order/all?page=${page}&limit=${limit}`

  //   if (filters.date) {
  //     url += `&date=${filters.date}`
  //   }
  //   if (filters.payType) {
  //     url += `&payType=${filters.payType}`
  //   }
  //   if (filters.status) {
  //     url += `&status=${filters.status}`
  //   }
  //   if (filters.statusAdmin) {
  //     url += `&statusAdmin=${filters.statusAdmin}`
  //   }
  //   if (filters.merchant) {
  //     url += `&merchant=${filters.merchant}`
  //   }
  //   if (filters.search) {
  //     url += `&search=${filters.search}`
  //   }

  //   await axios
  //     .get(url)
  //     .then((response) => {
  //       if (response.status == 200) {
  //         const { data } = response;
  //         setOrders(data.orders)
  //         setTotalRecords(data.pagination.totalRecords)
  //         setTotalPage(data.pagination.totalPage)
  //         setPage(data.pagination.currentPage)
  //       } else {
  //         setOrders(null)
  //         setTotalRecords(0)
  //         setTotalPage(0)
  //         setPage(1)
  //       }
  //     })
  //     .catch((error) => {
  //       const { response } = error
  //       if (response) {
  //         if (response?.status == 401) {
  //           toast.error("Please Login!");
  //           logoutUser();
  //         }
  //       }
  //       setOrders(null)
  //       setTotalRecords(0)
  //       setTotalPage(0)
  //       setPage(1)
  //     })
  // }


  useEffect(() => {
    const getMerchants = async () => {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/order/merchants`)
        .then((response) => {
          if (response.status == 200) {
            const { data } = response;
            setMerchants(data.merchants)
            const defaultM = _.find(data.merchants, (o) => o.default == true)
            // console.log(defaultM)
            if (defaultM) {
              setFilters((prevCount) => { return { ...prevCount, merchant: defaultM._id } })
            }
          } else {
            setMerchants(null)
          }
        })
        .catch((error) => {
          const { response } = error
          if (response) {
            if (response?.status == 401) {
              toast.error("Please Login!");
              logoutUser();
            }
          }
          setMerchants(null)
        })
    }

    if (!merchants) {
      getMerchants()
    }

    return () => { }
  }, [merchants])

  useEffect(() => {
    // getOrders()
    // intervalId = setInterval(() => {
    //   getOrders()
    // }, 5000);

    
      setQuery(prev=>{return{...prev, date:filters.date || undefined}})
      setQuery(prev=>{return{...prev, payType:filters.payType || undefined}})
      setQuery(prev=>{return{...prev, status:filters.status || undefined}})
      setQuery(prev=>{return{...prev, statusAdmin:filters.statusAdmin || undefined}})
      setQuery(prev=>{return{...prev, merchant:filters.merchant || undefined}})
      setQuery(prev=>{return{...prev, search:filters.search || undefined}})
      setQuery(prev=>{return{...prev, page:page || undefined}})
      setQuery(prev=>{return{...prev, limit:limit || undefined}})

    // ?page=${page}&limit=${limit}

    return () => {
      clearInterval(intervalId)
    }
  }, [page, limit, filters])
  

  // Approve Modal Start
  const [approveModalData, setapproveModalData] = useState(null)
  const [showApproveOrder, setShowApproveOrder] = useState(false)

  const handleApproveOrderShow = (transId, amount) => {
    setapproveModalData({ transId, amount })
    setShowApproveOrder(true)
  }
  const handleApproveOrderClose = () => {
    setapproveModalData(null)
    setShowApproveOrder(false)
  }
  const approveOrderModalClose = (type) => {
    handleApproveOrderClose()
    if (type == "success") {
      // getOrders()
      swrMutate()
    }
  }
  // Approve Modal End

  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title fw-semibold mb-4">Orders
          <span className='ms-2 pointer' onClick={swrMutate}>
            <i className="ti ti-reload"></i>
          </span>
        </h5>
        {approveModalData && (
          <ApproveOrderModal amount={approveModalData.amount} transId={approveModalData.transId} show={showApproveOrder} handleClose={approveOrderModalClose} />
        )}

        <div>
          <div className="row">
            <div className="col">
              <OrderFilters filters={filters} setFilters={setFilters} setPage={setPage} merchants={merchants} />
            </div>
          </div>

          <div className="row mt-1">
            <div className="col">
              <PerPage limit={limit} setLimit={setLimit} perPageArr={perPageArr} totalRecords={totalRecords} />
            </div>
          </div>
          {orders && !!orders.length && (
            <>
              <div className="table-responsive w-100">
                <table className="table text-nowrap mb-0">
                  <thead className="text-dark fs-4">
                    <tr>
                      <th className="border-bottom-0">
                        <h6 className="fw-semibold mb-0">Order </h6>
                      </th>
                      <th className="border-bottom-0">
                        <h6 className="fw-semibold mb-0">Details </h6>
                      </th>
                      <th className="border-bottom-0">
                        <h6 className="fw-semibold mb-0">UTR Number</h6>
                      </th>
                      <th className="border-bottom-0">
                        <h6 className="fw-semibold mb-0">Status</h6>
                      </th>
                      <th className="border-bottom-0">
                        <h6 className="fw-semibold mb-0">Created At</h6>
                      </th>
                      <th className="border-bottom-0">
                        <h6 className="fw-semibold mb-0">Options</h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders.map((order, key) => (
                      <OrderRecord order={order} handleApproveOrderShow={handleApproveOrderShow} key={key} getOrders={swrMutate} />
                    ))}
                  </tbody>
                </table>
              </div>
              <Paginator page={page} setPage={setPage} totalPage={totalPage} />
            </>
          )}

        </div>

      </div>
    </div>
  )
}

export default Orders