import axios from 'axios';
import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import toast from 'react-hot-toast';
import * as Yup from "yup";
import useCustomValidation from '../../../hooks/useCustomValidation';
import useLogout from '../../../hooks/useLogout';
import ValidationError from '../../ValidationError';

function CreateUPIModal({ createData, show, handleClose }) {
    const logoutUser = useLogout();

    const [isLoading, setIsLoading] = useState(false);

    const schema = {
        name: Yup.string().required("UPI Name is required").trim(),
        vpa: Yup.string().required("UPI Id is required").trim(),
    };
    const initial = {
        name: null,
        vpa: null,
    };
    const { register, errors, handleSubmit, reset, setValue } =
        useCustomValidation({ schema, initial });

    const createNewUpiSubmit = async (data) => {
        setIsLoading(true)
        await axios
            .post(`${process.env.REACT_APP_API_URL}/merchant/upi/create`,
                { ...data, merchant: createData.merchant })
            .then((response) => {
                const { data } = response;
                if (response.status == 201) {
                    toast.success(data.msg);
                    reset()
                    handleClose("success")
                } else {
                    toast.error(data.msg);
                }
            })
            .catch((error) => {
                const { response } = error
                if (response) {
                    const { data } = response
                    if (response?.status == 401) {
                        toast.error("Please Login!");
                        logoutUser();
                    } else if (response?.status == 400) {
                        toast.error(data.msg);
                    } else {
                        toast.error(data.msg || "Request failed!");
                    }
                }
            })
            .finally((e) => {
                setIsLoading(false)
            });

    }

    const handleOnHide = () => {
        reset()
        handleClose("manual")
    }

    return (
        <Modal show={show} onHide={handleOnHide}>
            <Modal.Header closeButton>
                <Modal.Title>Create UPI</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(createNewUpiSubmit)}>
                    <div className="mb-3">
                        <label htmlFor='c-upi-name' className="form-label">UPI Name</label>
                        <input type="text" {...register("name")} className="form-control"
                            id="c-upi-name" />
                        <ValidationError name="name" errors={errors} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor='c-upi-vpa' className="form-label">UPI Id</label>
                        <input {...register("vpa")} type="text" className="form-control"
                            id="c-upi-vpa" />
                        <ValidationError name="vpa" errors={errors} />
                    </div>

                    <button type="submit" className="btn btn-primary"
                        disabled={isLoading}>
                        {isLoading && (
                            <div className="spinner-border"></div>
                        )} Create
                    </button>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default CreateUPIModal